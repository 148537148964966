import React from "react";
import SelectRosterTabs from "../components/selectRosterTabs";

const SelectRoster = () => {

  return (
    <div className="container">
      <SelectRosterTabs />

      
    </div>
  );
};

export default SelectRoster;