import { combineReducers } from "redux";
import authReducer from "../Redux/Reducers/authReducer";
import messageReducer from "../Redux/Reducers/messageReducer";
import enrollmentsReducer from "../Redux/Reducers/enrollmentsReducer";
import coursesReducer from "../Redux/Reducers/coursesReducer";

export default combineReducers({
    auth: authReducer,
    msg: messageReducer,
    courses: coursesReducer,
    enrollments: enrollmentsReducer,
});