import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js/stable";

import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Provider } from "react-redux";
import store from "./Redux/store";

//import { unregister } from './serviceWorker';
//unregister();

ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>,
  document.getElementById('root')
);
