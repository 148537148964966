const protocol = process.env.REACT_APP_API_PROTOCOL;
const port = process.env.REACT_APP_API_PORT
  ? `:${process.env.REACT_APP_API_PORT}`
  : "";
const host = process.env.REACT_APP_API_HOST;
//let baseApiUrl = `${protocol}rlddocebo.${host}${port}/v1`;//"http://rlddocebo.localhost:8080/v1";
//let baseApiUrl = `${protocol}rldschoox.${host}${port}/v1`; //"http://rldschoox.localhost:8080/v1"
//let baseApiUrl = `${protocol}costafarms.${host}${port}/v1`; //"http://rldschoox.localhost:8080/v1"
const currentSiteHost = window.location.host; // returns the host portion of the URL
console.log("currentSiteHost: " + currentSiteHost);
//retrieve the company id from the URI
let companyId = currentSiteHost.substring(0, currentSiteHost.indexOf("."));
console.log("companyId from URL: " + companyId);

//derive baseApiUrl from current site host
let baseApiUrl = `${protocol}${companyId}.${host}${port}/v1`;
let baseSsoUrl = `${protocol}${companyId}.${host}${port}/sso`;

//Fallback for local testing

if (currentSiteHost.indexOf("localhost") > -1) {
  //companyId = "rlddocebo"; //'momentive';
  //companyId = "rldschoox"; //'costafarms';
  companyId = "ford"; //'rldoraclelearn';
  console.log("companyId local: " + companyId);
  baseApiUrl = `${protocol}${companyId}.localhost:8080/v1`;
}

console.log("baseApiUrl: " + baseApiUrl);

module.exports = global.config = {
  companyId: companyId,
  baseApiUrl: baseApiUrl,
  baseSsoUrl,
  data: {
    //Set the global variables here....
  },
};
