import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Column,
  Table,
  SortDirection,
  WindowScroller
} from "react-virtualized";
import _ from "lodash";
import { Checkbox, Button, Icon, List, Popup } from "semantic-ui-react";
import "react-virtualized/styles.css"; // only needs to be imported once
import { checkinLearners, markCompletionLearners, cancelCheckInLearners } from '../../Webservices/genericRestApis';
import { getLMSEnrollments, selectUser } from "../../Redux/Actions/enrollmentsAction";
import axios from "axios";
import '../../config';
import LoadSpinner from '../../components/LoadSpinner';
import DownloadCSV from './downloadCSV';
import { Tooltip } from 'react-tooltip';

//import { CSVLink } from 'react-csv';

export default function OraclelearnRosterTable() {
  const [sortBy, setSortBy] = useState("id");
  const [sortDirection, setSortDirection] = useState("ASC");
  const { enrollments: currentEnrollments } = useSelector(state => state.enrollments);
  const { course: currentCourse, session: currentSession, event: currentEvent } = useSelector(state => state.courses);
  const { user: currentUser } = useSelector(state => state.auth);
  const [enrollments, setEnrollments] = useState([]);

  const [popupStatusMsg, setPopupStatusMsg] = useState("");

  const [sortedList, setSortedList] = useState([]);
  const [checked, setChecked] = useState([]);
  const [completedLearners, setCompletedLearners] = useState([]);
  const [csvFileName, setCsvFileName] = useState("Person Number List (PNL)");

  const [isLoaded, setIsLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);

  const [hover, setHover] = useState(false);

  //let baseApiUrl = "http://rldoraclelearn.localhost:8080/v1";
  let baseApiUrl = global.config.baseApiUrl;
  
  const dispatch = useDispatch();
  
  const onHover = () => {
    setHover(!hover)
  }

  useEffect(() => {
    setIsLoaded(true);

    console.log('Enrollments: ' + JSON.stringify(currentEnrollments));
    if (currentEnrollments && currentEnrollments.data.enrollments) { 
      setEnrollments(currentEnrollments.data.enrollments);
      setSortedList(currentEnrollments.data.enrollments);
      if(currentEnrollments.data.enrollments) {
        for (var enrl in currentEnrollments.data.enrollments) {
          if (currentEnrollments.data.enrollments[enrl].completed === true) {
            completedLearners.push(currentEnrollments.data.enrollments[enrl].id);
          }
        }
        setCompletedLearners(completedLearners);
        //console.log('completedLearners: ' + JSON.stringify(completedLearners));
      }
    } else {
      setEnrollments([]);
      setSortedList([]);
    }
    setCsvFileName("Person Number List (PNL) " + sessionStorage.getItem('course_number') 
        + '_' + sessionStorage.getItem('offering_number') + '_' + sessionStorage.getItem('activity_number'));
  }, [currentEnrollments]);

  /*
  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
        csvLink.current.link.click();
        setDownloadReady(false);
    }
  }, [bDownloadReady]);

  const handleAction = (actionType) => {
    if (actionType === 'DOWNLOAD') {
        //get data here
        const reportData = getMockReport(surveyId);
        this.setState({ data: reportData }, () => {
          this.surveyLink.link.click()
        });
        setDataForDownload(newDataForDownload);
        setDownloadReady(true);
    }
  }
  */

  // eslint-disable-next-line no-shadow
  function _sortList({ sortBy, sortDirection }) {
    console.log('sortedList:enrollments: ' + JSON.stringify(enrollments));
    const newList = _.sortBy(enrollments, [sortBy]);
      if (sortDirection === SortDirection.DESC) {
        newList.reverse();
      }

    return newList;
  }

  // eslint-disable-next-line no-shadow
  function _sort({ sortBy, sortDirection }) {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
    setSortedList(_sortList({ sortBy, sortDirection }));
  }

  async function performCheckIn(e) {
    setIsLoaded(false);
    console.log('performCheckIn buttonClick: ' + JSON.stringify(e));
    let learnersArray = [];
    let failed = false;
    console.log("performCheckIn:Learners: " + JSON.stringify(checked));
    
    if (checked.length>0) {
      learnersArray = [...checked];
      console.log("performCheckIn:Learners:checked.length>0: " + JSON.stringify(learnersArray));
    } else {
      learnersArray.push(e.id);
      console.log("performCheckIn:Learners:NEW-RECORD: " + JSON.stringify(learnersArray));
    }

    let course_number = sessionStorage.getItem('course_number');
    let offering_number = sessionStorage.getItem('offering_number');
    let activity_number = sessionStorage.getItem('activity_number');
    let offering_start_date = sessionStorage.getItem('offering_start_date');
    let offering_end_date = sessionStorage.getItem('offering_end_date');
    console.log("performCheckIn:Learners:course_number:offering_number:activity_number: " + course_number + " - " + offering_number + " - " + activity_number);

    let reqBody = {"courseNumber": course_number,
                     "offeringNumber": offering_number,
                     "activityNumber": activity_number,
                     "learners": learnersArray[0],
                     "offeringStartDate": offering_start_date,
                     "offeringEndDate": offering_end_date,
                     "name": e.name,
                     "enrolled": e.enrolled,
                     "completed": e.completed,
                     "activityAttemptNumber": e.activityAttemptNumber,
                     "completionDate": e.completionDate,
                     "hcmDlStatus": e.hcmDlStatus,
                     "hcmDlStatusMessage": e.hcmDlStatusMessage,
                     "instructorNumber": e.instructorNumber, 
                     "instructorName": e.instructorName, 
                     "requestId": e.requestId,
                     "contentId": e.contentId,
                     "learningRecordNumber": e.learningRecordNumber,
                     "assignmentNumber": e.assignmentNumber
                    };
    console.log('performCheckIn reqBody: ' + JSON.stringify(reqBody));
    let response = await checkinLearners(reqBody);
    console.log('checkin response: ' + JSON.stringify(response));
    if (response) {
      dispatch(getLMSEnrollments(response, failed));
    }
    setIsLoaded(true);
  }

  async function cancelCheckIn(e) {
    setIsLoaded(false);
    console.log('cancelCheckIn buttonClick: ' + JSON.stringify(e));
    let learnersArray = [];
    var failed = false;
    console.log("cancelCheckIn:Learners:checked " + JSON.stringify(checked));
    
    if (checked.length>0) {
      learnersArray = [...checked];
    } else {
      learnersArray.push(e.id);
    }
    console.log("cancelCheckIn:Learners:learnersArray " + JSON.stringify(learnersArray));

    let course_number = sessionStorage.getItem('course_number');
    let offering_number = sessionStorage.getItem('offering_number');
    let activity_number = sessionStorage.getItem('activity_number');
    console.log("cancelCheckIn:Learners:course_number:offering_number:activity_number: " + course_number + " - " + offering_number + " - " + activity_number);

    let reqBody = {"courseNumber": course_number,
                     "offeringNumber": offering_number,
                     "activityNumber": activity_number,
                     "cancel_checkin": "true",
                     "mark_completion": "",
                     "learners": learnersArray};
    console.log('cancelCheckIn reqBody: ' + JSON.stringify(reqBody));
    let response = await cancelCheckInLearners(reqBody);
    console.log('cancelCheckIn response: ' + JSON.stringify(response));
    if (response) {
      dispatch(getLMSEnrollments(response, failed));
    }
    setIsLoaded(true);
  }

  async function performCompletion(e) {
    setIsLoaded(false);
    console.log('performCompletion buttonClick: ' + JSON.stringify(e));
    let learnersArray = [];
    var failed = false;
    console.log("performCompletion:Learners:checked " + JSON.stringify(checked));
    
    if (checked.length>0) {
      learnersArray = [...checked];
    } else {
      learnersArray.push(e.id);
    }
    console.log("performCompletion:Learners:learnersArray " + JSON.stringify(learnersArray));

    let course_number = sessionStorage.getItem('course_number');
    let offering_number = sessionStorage.getItem('offering_number');
    let activity_number = sessionStorage.getItem('activity_number');
    console.log("performCompletion:Learners:course_number:offering_number:activity_number: " + course_number + " - " + offering_number + " - " + activity_number);

    let reqBody = {"courseNumber": course_number,
                    "offeringNumber": offering_number,
                    "activityNumber": activity_number,
                     "cancel_checkin": "",
                     "mark_completion": "true",
                     "learners": learnersArray};
    console.log('performCompletion: ' + JSON.stringify(reqBody));
    setDisabled(true);
    let response = await markCompletionLearners(reqBody);
    console.log('performCompletion response: ' + JSON.stringify(response));
    if (response) {
      dispatch(getLMSEnrollments(response, failed));
      setDisabled(false);
    }
    setIsLoaded(true);
  }

  function _cellRendererButtonCheckIn(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
        >
          {(sortedList[rowIndex]['hcmDlStatus'].includes('completion')) ?
                        <Button disabled={true} primary onClick={() => cancelCheckIn(sortedList[rowIndex])}>Cancel Check-in</Button>
                        : (sortedList[rowIndex][dataKey] != null) ?
                          <Button primary onClick={() => cancelCheckIn(sortedList[rowIndex])}>Cancel Check-in</Button>
                          : <Button primary onClick={() => performCheckIn(sortedList[rowIndex])}>Check-in</Button>}
        </div>
      </>
    );
  }

  function _cellRendererButtonWalkIn(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
        >
          {sortedList[rowIndex][dataKey] === true ?
                        <Icon name='male' size='large' />
                        : <Icon name='registered' size='large' />}
        </div>
      </>
    );
  }

  function _cellRendererButtonCompleted(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
        >
          {sortedList[rowIndex]['hcmDlStatus'] === 'completion-success' ?
                        <Icon color='green' name='checkmark' size='large' />
                        : (sortedList[rowIndex]['hcmDlStatus']  === 'enrollment-success' && sortedList[rowIndex]['checkedInDate']  != null) ? 
                              <Button primary onClick={() => performCompletion(sortedList[rowIndex])}>Mark Complete</Button> :
                                (sortedList[rowIndex]['hcmDlStatus']  === 'enrollment-success' && sortedList[rowIndex]['checkedInDate']  === null) ?
                                  <Button primary disabled={true} onClick={() => performCompletion(sortedList[rowIndex])}>Mark Complete</Button>
                                    : (sortedList[rowIndex]['hcmDlStatus']  === 'completion-pending') ? <Icon color='green' name='question' size='large' />
                                      : <Popup content={popupStatusMsg} trigger={<Icon color='red' name='minus' size='large' />} /> }
        </div>
      </>
    );
  }

  function _cellRenderer(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
          style={sortedList[rowIndex][dataKey].includes("success")  ? { color: "green" } : { color: "red" }}
        >
          {sortedList[rowIndex][dataKey] === null ? 
                        <Popup content={popupStatusMsg} trigger={<Icon color='red' name='minus' size='large' />} />
                      : sortedList[rowIndex][dataKey]}
        </div>
      </>
    );
  }

  function _cellRendererDate(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
          style={sortedList[rowIndex][dataKey] != null  ? { color: "green" } : { color: "red" }}
        >
          {(sortedList[rowIndex][dataKey] === null || sortedList[rowIndex][dataKey] === '') ? 
                        <Popup content={popupStatusMsg} trigger={<Icon color='red' name='minus' size='large' />} />
                      : sortedList[rowIndex][dataKey]}
        </div>
      </>
    );
  }

  function _cellRendererName(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
          style={{color: "blue"}}
        >
          {sortedList[rowIndex][dataKey] === null ? 
                        <Popup content={popupStatusMsg} trigger={<Icon color='red' name='minus' size='large' />} />
                      : sortedList[rowIndex][dataKey]}
        </div>
      </>
    );
  }

  function _cellRendererHover(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
          data-tooltip-id='toolTipHover' data-tooltip-place='top'>
            {(sortedList[rowIndex][dataKey] != null && sortedList[rowIndex][dataKey] != '') ?
              <div><Icon color='red' name='info circle' size='large' /><Tooltip id="toolTipHover" className="tooltip">{"Status: " + sortedList[rowIndex][dataKey]}</Tooltip></div>
              : ''}
        </div>
      </>
    );
  }

  const _onChangeHeaderCheckbox = data => {
    data.checked ? setChecked(sortedList.map(row => row.id)) : setChecked([]);
  };

  const _onChangeRowCheckbox = data => {
    const newRow = sortedList[data.index].id;
    console.log('newRow: ' + JSON.stringify(sortedList[data.index]));
    checked.includes(newRow)
      ? setChecked(old => old.filter(row => row !== newRow))
      : setChecked(old => [...old, newRow]);
  };
  
  return (
      <>
        <div className="right">
          <DownloadCSV data={completedLearners} fileName={csvFileName} />
        </div>
        <hr />
        { sortedList != '' ?
        <div stylename="editable-list">
        { isLoaded ?
        <WindowScroller>
          {({ height, isScrolling, onChildScroll, scrollTop }) => (
            <Table
              autoHeight
              width={1200}
              height={height ? height : 550}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              scrollTop={scrollTop}
              headerHeight={50}
              rowHeight={50}
              sort={_sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              rowCount={sortedList.length}
              rowGetter={({ index }) => sortedList[index]}
            >
            {/*
              <Column
                disableSort
                dataKey="checkbox"
                width={60}
                headerRenderer={() => (
                  <Checkbox
                    indeterminate={
                      checked.length > 0 && checked.length < sortedList.length
                    }
                    checked={checked.length === sortedList.length}
                    onChange={(e, data) => _onChangeHeaderCheckbox(data)}
                  />
                )}
                cellRenderer={({ rowIndex }) => (
                  <Checkbox
                    checked={checked.includes(sortedList[rowIndex].id) === true}
                    onChange={(e, data) => _onChangeRowCheckbox(data)}
                    index={rowIndex}
                  />
                )}
              />
              */}
              <Column
                dataKey="name"
                label="NAME"
                width={320}
                cellRenderer={({ dataKey, rowIndex }) =>
                  _cellRendererName(dataKey, rowIndex)
                }
              />
              <Column
                dataKey="checkedInDate"
                label="CHECK-IN?"
                width={180}
                cellRenderer={({ dataKey, rowIndex }) =>
                    _cellRendererButtonCheckIn(dataKey, rowIndex)
                }
              />
              <Column
                dataKey="walkin"
                label="WALK-IN/PRE-REG?"
                width={140}
                cellRenderer={({ dataKey, rowIndex }) =>
                    _cellRendererButtonWalkIn(dataKey, rowIndex)
                }
              />
              <Column
                dataKey="hcmDlStatus"
                label="COMPLETED?"
                width={180}
                cellRenderer={({ dataKey, rowIndex }) =>
                    _cellRendererButtonCompleted(dataKey, rowIndex)
                }
              />
              <Column
                dataKey="completionDate"
                label="COMPLETION-DATE?"
                width={180}
                cellRenderer={({ dataKey, rowIndex }) =>
                  _cellRendererDate(dataKey, rowIndex)
                }
              />
              <Column
                dataKey="hcmDlStatus"
                label="STATUS?"
                width={160}
                cellRenderer={({ dataKey, rowIndex }) =>
                  _cellRenderer(dataKey, rowIndex)
                }
              />
              {/*
              <Column
                dataKey="hcmDlStatusMessage"
                label="DETAILS?"
                width={140}
                cellRenderer={({ dataKey, rowIndex }) =>
                  _cellRendererHover(dataKey, rowIndex)
                }
              />
              */}
            </Table>
          )}
        </WindowScroller>
        : <LoadSpinner />}
      </div>
      :
      <div className="div-text"><b>No Enrollments found!!!</b></div> }
      <hr/>
      <hr/>
      {/*
      <div>
        <button type="button" className="btn btn-outline-sysmode btn-sm" onClick={(e) => handleAction('DOWNLOAD')}>Download</button>
          <CSVLink 
            data={dataForDownload} 
            filename="data.csv"
            className="hidden"
            ref={csvLink}
            target="_blank" />
      </div>
        */}
      </>
  );
}
