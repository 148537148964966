import { checkSsoEnabled, authLogin, getCourses } from "./genericRestApis";

/* 
      *** This layer converts vendor-specific parameter names to the generic parameters used by this tool. ***
      
      The JSON object defined as the 'transposer' converts queries, bodies and responses based on the following:
      - Vendor name  [docebo, sumtotal etc...]
      - Object type  [person, course etc..]
      - Transaction types:
          - query / body / response
    
      In the transposer JSON definintion, the *keys* are the generic parameter names used by this UI tool 
      and the *values* are the vendor-specific paramter names for calling and parsing vendor methods.
      Only the relevant and required paramers need to be defined in the mapping JSON.  
      The rest can be ignored provided they are not required by either side.
    */

let transposer = require("./lmsApiObjectMaps.json");
// Set for which vendor's APIs you want to transpose in case there are more than one in the transposer you are using:
let transposingFor = "lms";

// ====== Exported methods to React UI layer
export async function genericCheckSsoEnabled(reqObject) {
  let results = {};
  try {
    results = await checkSsoEnabled(reqObject);
    var ssoInfo = results.data.ssoInfo;
    //console.log("results result ONE: " + JSON.stringify(results));
    //console.log(
    //  "results result ONE: " + ssoInfo.companyPlatformAttributes.value
    //);
    var result = {};
    //drill in to the attributes to get the relevant info
    if (
      ssoInfo &&
      ssoInfo.companyPlatformAttributes &&
      ssoInfo.companyPlatformAttributes.value
    ) {
      //deep copy
      result = JSON.parse(ssoInfo.companyPlatformAttributes.value);
      //console.log("ssoInfo result ONE: " + JSON.stringify(result));
    }

    //console.log("genericCheckSsoEnabled result ONE: " + JSON.stringify(result));

  } catch (e) {
    console.log(
      "Api Transposer: genericCheckSsoEnabled() Error loading data: " + e
    );
  }
  return result;
}

export async function genericAuthLogin(reqObject) {
  let results = {};
  try {
    results = await authLogin(reqObject);
    console.log("genericAuthLogin results: " + JSON.stringify(results));
    var data = results.data.userInfo;
    var result = {};
    data.forEach((item) => {
      result = Object.assign(result, item);
    });

    console.log("genericAuthLogin result ONE: " + JSON.stringify(result));

    if (!result.error) {
      //localStorage.setItem("user", JSON.stringify(result));
      sessionStorage.setItem("user", JSON.stringify(result)); 
    }
  } catch (e) {
    console.log("Api Transposer: genericAuthLogin() Error loading data: " + e);
    return null;
  }
  // TBD
  //return transposeResponseObject("login", results.data.resObj);
  return result;
}

export async function genericOfferings(reqObject) {
  let results = {};
  try {
    results = await getCourses(reqObject);

    console.log("getCourses result ONE: " + JSON.stringify(results));
  } catch (e) {
    console.log("Api Transposer: getCourses() Error loading data: " + e);
  }
  // TBD
  //return transposeResponseObject("login", results.data.resObj);
  return results;
}

export function genericAuthLogout() {
  try {
    //localStorage.removeItem("user");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("courses_offerings_activities");
  } catch (e) {
    console.log("Api Transposer: genericAuthLogout() Error: " + e);
  }
}
// ========= Local methods ===========

function buildQueryString(objectType, queryObject) {
  let queryTemplate = transposer[transposingFor][objectType].query;
  var keys = Object.keys(queryTemplate);
  let queryString = "";
  for (const k of keys) {
    if (
      queryObject[k] !== null &&
      queryObject[k] !== "" &&
      typeof queryObject[k] !== "undefined"
    ) {
      queryString +=
        "&" + queryTemplate[k] + "=" + encodeURIComponent(queryObject[k]);
    }
  }
  return queryString;
}

/*function buildRequestBody(objectType, queryObject) {
    let queryTemplate = transposer[transposingFor][objectType].query;
    var keys = Object.keys(queryTemplate);
    let reqBody = new Object();
    for (const k of keys) {
      if (queryObject[k] !== null && queryObject[k] !== "" && typeof queryObject[k] !== "undefined") {
        queryString += "&" + queryTemplate[k] + "=" + encodeURIComponent(queryObject[k]);
      }
    }
    return queryString;
  }*/

/* 
      This method converts the passed JSON query values into a Vendor-formatted JSON Body object.
      The conversion mapping is defined in the transposer imported at the top of this file.
    */
function transposeBodyObject(objectType, bodyObject) {
  let bodyTemplate = transposer[transposingFor][objectType].body;
  var keys = Object.keys(bodyTemplate);
  let body = {};
  for (const k of keys) {
    if (bodyObject[k] !== null && bodyObject[k] !== "") {
      if (bodyTemplate[k].includes("[")) {
        let arrayName = bodyTemplate[k];
        arrayName = arrayName.replace("[", "");
        arrayName = arrayName.replace("]", "");
        body[arrayName] = [];
        body[arrayName] = bodyObject[k];
      } else {
        body[bodyTemplate[k]] = bodyObject[k];
      }
    }
  }
  return body;
}

/* 
      This method converts the Vendor response JSON data into a generic JSON format used by this tool.
      The conversion mapping is defined in the transposer imported at the top of this file.
    */
function transposeResponseObject(objectType, objectArray) {
  if (!Array.isArray(objectArray)) {
    objectArray = [objectArray];
  }
  let objectTemplate = transposer[transposingFor][objectType].response;
  var keys = Object.keys(objectTemplate);
  let transposedObjectArray = [];
  for (const obj of objectArray) {
    let newObj = {};
    for (const k of keys) {
      if (Array.isArray(objectTemplate[k])) {
        let vendorArrayName = objectTemplate[k][0].vendorArrayName;
        let arrayData = obj[vendorArrayName];
        newObj[k] = [];
        let arrayKeys = Object.keys(objectTemplate[k]);
        if (typeof arrayData !== "undefined") {
          if (JSON.stringify(arrayData).includes("{") && arrayKeys.length > 1) {
            for (const v of arrayData) {
              let arrayElement = {};
              for (var i = 0; i < objectTemplate[k].length; i++) {
                let genericKey = Object.keys(objectTemplate[k][i]);
                let vendorKey = objectTemplate[k][i][genericKey];
                arrayElement[genericKey] = v[vendorKey];
              }
              newObj[k].push(arrayElement);
            }
          } else {
            newObj[k] = arrayData;
          }
        }
      } else {
        newObj[k] = obj[objectTemplate[k]];
      }
    }
    transposedObjectArray.push(newObj);
  }
  //console.log("Transposed object: ");
  //console.log(JSON.stringify(transposedObjectArray));
  return transposedObjectArray;
}
