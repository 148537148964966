import React, { useState, useEffect } from "react";
import { Transition } from 'semantic-ui-react';
import People from '../../assets/images/people.png';
import Bckgrnd from '../../assets/images/background.jpg';
import vid from '../../assets/video/vid_loop_854x480.mp4';
import $ from 'jquery';
import './main.css';

const Home = () => {
  const duration = 300;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  }

  const transitionStyles = {
    entering: { opacity: 1 },
    entered:  { opacity: 1 },
    exiting:  { opacity: 0 },
    exited:  { opacity: 0 },
  };
  
  //$(document).ready(function () {	
    //$('#subtext').transition('scale in', 300);
  //});

  return (
    <>
      <div id="splashContainer">
	      <span> 
		      <img src={People} />
	      </span> 
	      <span style={{width: 40}}></span> 
	      <span>
		      <div className="splash">Automate Roster and Attendance Management</div>
		      <div className="splash-sub">Use company badges, barcodes, or mobile
			        devices for registration, attendance and completion without touching
			        your LMS.
          </div>
	      </span>

	      <Transition visible={true} animation='scale' duration={500}>
          <div id="subtext">
            <span>
			        STOP using error prone spreadsheets and paper sign-in forms to track workshop and classroom attendance.
		        </span>
		
		        <span>
			        START leveraging the power of barcodes and electronic submission to accurately capture enrollments. 		
		        </span>
		
		        <span>
			        INTEGRATE with existing Learning Management Solutions and other third-party systems.
		        </span>
          </div>
  </Transition>
      </div>

      <a id="learnMore"></a>
      <div style={{position:'relative',width:'100%',height:120}}>
	      <div className="left-half-slope bluebkg"></div>
	      <div className="right-half-slope bluebkg"></div>
      </div>

      <div id="marketingTextContainer">
	      <div className="marketingText table" style={{position:'relative'}}>
	        <div className="marketingText tableCell centeredText" style={{width:'35%'}}>
			      <span className="marketingText subHead">Eliminates</span>
			      <br/>
			      Paper Sign-in Sheets<br/>
			      Manual Registrations<br/>
			      Manual Attendance<br/>
			      Manual Completion<br/>
		      </div>
		
		      <div className="marketingText tableCell centeredText" style={{width:'30%'}}>
			      <span className="marketingText subHead">Updates your LMS</span>
			      <p>
			        Automated Registrations<br/>
			        Automated Attendance<br/>
			        Automated Completions<br/>
            </p>
		      </div>
		
		      <div className="marketingText tableCell centeredText" style={{width:'35%'}}>
			      <span className="marketingText subHead">Features</span>
			      <p>
			        Real-time Roster Management<br/>
			        Validate Instructors<br/>
			        Validate Learners<br/>
			        Robust scanner support<br/>
            </p>
		      </div>			
	      </div>

	      <div className="marketingText table" style={{paddingBottom:20}}>
		      <div className="marketingText tableCell">	
			      <div className="marketingText subHead2 centeredText">
				      <span>Easy</span><span style={{paddingLeft:65,paddingRight:65}}>Accurate</span><span>Real-Time</span>
			      </div>
	
			      <div className="marketingText">
				      <b>Partner with <i>rapid<span style={{color:'red'}}>LD</span></i></b><br/>
				        Leverage our expertise to develop and talent and learning solution for your company. To learn more about <b><i>rapid<span style={{color:'red'}}>LD</span></i></b> services
				        and success stories or to partner with <b><i>rapid<span style={{color:'red'}}>LD</span></i></b>, write us at <a href="mailto:info@@rapidld.com">info@@rapidld.com</a> or visit us at <a href="http://www.rapidld.com" target="_new">www.rapidld.com</a>
			      </div>
		      </div>
	      </div>
		
      </div>

      <video id="bgvid" muted autoPlay loop poster={Bckgrnd}>
	      <source type="video/mp4" src={vid} />
      </video>

  </>
  );
};

export default Home;