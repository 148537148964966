import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Column,
  Table,
  SortDirection,
  WindowScroller
} from "react-virtualized";
import _ from "lodash";
import { Checkbox, Button, Icon, List, Popup } from "semantic-ui-react";
import "react-virtualized/styles.css"; // only needs to be imported once
import { checkinLearners, markAttendanceLearners, markCompletionLearners, cancelCheckInLearners } from '../../Webservices/genericRestApis';
import { getLMSEnrollments, selectUser } from "../../Redux/Actions/enrollmentsAction";
import axios from "axios";
import './../../config';
import LoadSpinner from '../../components/LoadSpinner';

//import list from "./list";
//import currentEnrollments from "../../data/doceboEnrollments";

export default function DoceboRosterTable() {
  const [sortBy, setSortBy] = useState("id");
  const [sortDirection, setSortDirection] = useState("ASC");
  const { enrollments: currentEnrollments } = useSelector(state => state.enrollments);
  const { course: currentCourse, session: currentSession, event: currentEvent } = useSelector(state => state.courses);
  const { user: currentUser } = useSelector(state => state.auth);
  const [enrollments, setEnrollments] = useState([]);

  const [evaluationType, setEvaluationType] = useState("");
  const [sessionScoreBase, setSessionScoreBase] = useState("");
  const [minAttendedEventsForCompletion, setMinAttendedEventsForCompletion] = useState("");
  const [popupStatusMsg, setPopupStatusMsg] = useState("");

  const [sortedList, setSortedList] = useState([]);
  const [checked, setChecked] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);

  //let baseApiUrl = "http://rlddocebo.localhost:8080/v1";
  let baseApiUrl = global.config.baseApiUrl;
  
  const dispatch = useDispatch(); 

  useEffect(() => {
    setIsLoaded(true);

    setEvaluationType(currentEvent.session_evaluation_type);
    setSessionScoreBase(currentEvent.session_score_base);
    setMinAttendedEventsForCompletion(currentEvent.session_min_attended_events_for_completion);

    if (minAttendedEventsForCompletion === "") {
      setPopupStatusMsg("");
    } else if (minAttendedEventsForCompletion === "0") {
      setPopupStatusMsg("The session is marked as completed when the learner has attended all events");
    } else {
      setPopupStatusMsg("The session is marked as completed "
            + "when the learner has attended " + minAttendedEventsForCompletion  + " events");
    }
    
    console.log('Session Evaluation Info : ' + evaluationType + " - " + sessionScoreBase + " -- "
                        + minAttendedEventsForCompletion);

    console.log('Enrollments: ' + JSON.stringify(currentEnrollments));
    if (currentEnrollments) { 
      setEnrollments(currentEnrollments.data.enrollments);
      setSortedList(currentEnrollments.data.enrollments);
    }
  }, [evaluationType, sessionScoreBase, minAttendedEventsForCompletion, currentEnrollments]);

  // eslint-disable-next-line no-shadow
  function _sortList({ sortBy, sortDirection }) {
    const newList = _.sortBy(enrollments, [sortBy]);
    if (sortDirection === SortDirection.DESC) {
      newList.reverse();
    }
    return newList;
  }

  // eslint-disable-next-line no-shadow
  function _sort({ sortBy, sortDirection }) {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
    setSortedList(_sortList({ sortBy, sortDirection }));
  }

  async function performCheckIn(e) {
    setIsLoaded(false);
    console.log('performCheckIn buttonClick: ' + JSON.stringify(e));
    let learnersArray = [];
    let failed = false;
    console.log("performCheckIn:Learners: " + JSON.stringify(checked));
    
    if (checked.length>0) {
      learnersArray = [...checked];
      console.log("performCheckIn:Learners:checked.length>0: " + JSON.stringify(learnersArray));
    } else {
      learnersArray.push(e.id);
      console.log("performCheckIn:Learners:NEW-RECORD: " + JSON.stringify(learnersArray));
    }

    let reqBody = {"courseId": currentCourse.course_id,
                     "sessionId": currentSession.session_id,
                     "eventId": currentEvent.event_id,
                     "accessToken": currentUser.accessToken,
                     "learners": learnersArray};
    console.log('performCheckIn reqBody: ' + JSON.stringify(reqBody));
    let response = await checkinLearners(reqBody);
    console.log('checkin response: ' + JSON.stringify(response));
    if (response) {
      dispatch(getLMSEnrollments(response, failed));
    }
    setIsLoaded(true);
  }

  async function cancelCheckIn(e) {
    setIsLoaded(false);
    console.log('cancelCheckIn buttonClick: ' + JSON.stringify(e));
    let learnersArray = [];
    var failed = false;
    console.log("cancelCheckIn:Learners:checked " + JSON.stringify(checked));
    
    if (checked.length>0) {
      learnersArray = [...checked];
    } else {
      learnersArray.push(e.id);
    }
    console.log("cancelCheckIn:Learners:learnersArray " + JSON.stringify(learnersArray));

    let reqBody = {"courseId": currentCourse.course_id,
                     "sessionId": currentSession.session_id,
                     "eventId": currentEvent.event_id,
                     "accessToken": currentUser.accessToken,
                     "cancel_checkin": "true",
                     "mark_attendance": "",
                     "mark_completion": "",
                     "learners": learnersArray};
    console.log('cancelCheckIn reqBody: ' + JSON.stringify(reqBody));
    let response = await cancelCheckInLearners(reqBody);
    console.log('cancelCheckIn response: ' + JSON.stringify(response));
    if (response) {
      dispatch(getLMSEnrollments(response, failed));
    }
    setIsLoaded(true);
  }

  async function performAttendance(e) {
    setIsLoaded(false);
    console.log('performAttendance buttonClick: ' + JSON.stringify(e));
    let learnersArray = [];
    var failed = false;
    console.log("performAttendance:Learners:checked: " + JSON.stringify(checked));
    
    if (checked.length>0) {
      learnersArray = [...checked];
    } else {
      learnersArray.push(e.id);
    }
    console.log("performAttendance:Learners:learnersArray " + JSON.stringify(learnersArray));

    let reqBody = {"courseId": currentCourse.course_id,
                     "sessionId": currentSession.session_id,
                     "eventId": currentEvent.event_id,
                     "accessToken": currentUser.accessToken,
                     "cancel_checkin": "",
                     "mark_attendance": "true",
                     "mark_completion": "",
                     "learners": learnersArray};
    console.log('performAttendance: ' + JSON.stringify(reqBody));
    let response = await markAttendanceLearners(reqBody);
    console.log('performAttendance response: ' + JSON.stringify(response));
    if (response) {
      dispatch(getLMSEnrollments(response, failed));
    }
    setIsLoaded(true);
  }

  async function performCompletion(e) {
    setIsLoaded(false);
    console.log('performCompletion buttonClick: ' + JSON.stringify(e));
    let learnersArray = [];
    var failed = false;
    console.log("cancelCheckIn:Learners:checked " + JSON.stringify(checked));
    
    if (checked.length>0) {
      learnersArray = [...checked];
    } else {
      learnersArray.push(e.id);
    }
    console.log("cancelCheckIn:Learners:learnersArray " + JSON.stringify(learnersArray));

    let reqBody = {"courseId": currentCourse.course_id,
                     "sessionId": currentSession.session_id,
                     "eventId": currentEvent.event_id,
                     "accessToken": currentUser.accessToken,
                     "cancel_checkin": "",
                     "mark_attendance": "",
                     "mark_completion": "true",
                     "learners": learnersArray};
    console.log('performCompletion: ' + JSON.stringify(reqBody));
    let response = await markCompletionLearners(reqBody);
    console.log('performCompletion response: ' + JSON.stringify(response));
    if (response) {
      dispatch(getLMSEnrollments(response, failed));
    }
    setIsLoaded(true);
  }

  function _cellRendererButtonCheckIn(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
        >
          {sortedList[rowIndex][dataKey] === 1 ?
                        <Button primary onClick={() => cancelCheckIn(sortedList[rowIndex])}>Cancel Check-in</Button>
                        : sortedList[rowIndex][dataKey] === 2 ? <Icon color='green' name='checkmark' size='large' />
                        : <Button primary onClick={() => performCheckIn(sortedList[rowIndex])}>Check-in</Button>}
        </div>
      </>
    );
  }

  function _cellRendererButtonWalkIn(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
        >
          {sortedList[rowIndex][dataKey] === 1 ?
                        <Icon name='male' size='large' />
                        : <Icon name='registered' size='large' />}
        </div>
      </>
    );
  }

  function _cellRendererButtonAttended(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
        >
          {sortedList[rowIndex][dataKey] === '1' ?
                        <Icon color='green' name='checkmark' size='large' />
                        : <Button primary onClick={() => performAttendance(sortedList[rowIndex])}>Mark Attended</Button>}
        </div>
      </>
    );
  }

  function _cellRendererButtonCompleted(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
        >
          {sortedList[rowIndex][dataKey] === '1' ?
                        <Icon color='green' name='checkmark' size='large' />
                        : evaluationType === '2' ? <Popup content={popupStatusMsg} trigger={<Icon color='red' name='minus' size='large' />} />
                                                  : <Button primary onClick={() => performCompletion(sortedList[rowIndex])}>Mark Complete</Button> }
        </div>
      </>
    );
  }

  function _cellRenderer(dataKey, rowIndex) {
    return (
      <>
        <div
          index={rowIndex}
          role="gridcell"
          style={dataKey === "status" ? { color: "green" } : {}}
        >
          {sortedList[rowIndex][dataKey]}
        </div>
      </>
    );
  }

  const _onChangeHeaderCheckbox = data => {
    data.checked ? setChecked(sortedList.map(row => row.id)) : setChecked([]);
  };

  const _onChangeRowCheckbox = data => {
    const newRow = sortedList[data.index].id;
    console.log('newRow: ' + JSON.stringify(sortedList[data.index]));
    checked.includes(newRow)
      ? setChecked(old => old.filter(row => row !== newRow))
      : setChecked(old => [...old, newRow]);
  };
  
  return (
      <>
        <div>
            {/*<button onClick={() => {
                    console.log('Enrollments: ' + JSON.stringify(currentEnrollments));
                    if (currentEnrollments) { 
                    setEnrollments(currentEnrollments.data.enrollments);
                    setSortedList(currentEnrollments.data.enrollments);
                  }
                }
            }>
                Load Enrollments
            </button>
          */}
        </div>
        <hr />
        { sortedList != '' ?
        <div stylename="editable-list">
        { isLoaded ?
        <WindowScroller>
          {({ height, isScrolling, onChildScroll, scrollTop }) => (
            <Table
              autoHeight
              width={1000}
              height={height ? height : 550}
              isScrolling={isScrolling}
              onScroll={onChildScroll}
              scrollTop={scrollTop}
              headerHeight={50}
              rowHeight={50}
              sort={_sort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              rowCount={sortedList.length}
              rowGetter={({ index }) => sortedList[index]}
            >
              <Column
                disableSort
                dataKey="checkbox"
                width={60}
                headerRenderer={() => (
                  <Checkbox
                    indeterminate={
                      checked.length > 0 && checked.length < sortedList.length
                    }
                    checked={checked.length === sortedList.length}
                    onChange={(e, data) => _onChangeHeaderCheckbox(data)}
                  />
                )}
                cellRenderer={({ rowIndex }) => (
                  <Checkbox
                    checked={checked.includes(sortedList[rowIndex].id) === true}
                    onChange={(e, data) => _onChangeRowCheckbox(data)}
                    index={rowIndex}
                  />
                )}
              />
              <Column
                dataKey="name"
                label="NAME"
                width={180}
                cellRenderer={({ dataKey, rowIndex }) =>
                  _cellRenderer(dataKey, rowIndex)
                }
              />
              <Column
                dataKey="checked_in"
                label="CHECK-IN?"
                width={240}
                cellRenderer={({ dataKey, rowIndex }) =>
                    _cellRendererButtonCheckIn(dataKey, rowIndex)
                }
              />
              <Column
                dataKey="walkin"
                label="WALK-IN/PRE-REG?"
                width={240}
                cellRenderer={({ dataKey, rowIndex }) =>
                    _cellRendererButtonWalkIn(dataKey, rowIndex)
                }
              />
              <Column
                id="attended"
                dataKey="attended"
                label="ATTENDED?"
                width={300}
                style={{ justifyContent: "space-evenly" }}
                cellRenderer={({ dataKey, rowIndex }) =>
                    _cellRendererButtonAttended(dataKey, rowIndex)
                }
              />
              <Column
                dataKey="completed"
                label="COMPLETED?"
                width={400}
                cellRenderer={({ dataKey, rowIndex }) =>
                    _cellRendererButtonCompleted(dataKey, rowIndex)
                }
              />
            </Table>
          )}
        </WindowScroller>
        : <LoadSpinner />}
      </div>
      :
      <div>No Enrollments found</div> }
      </>
  );
}

//ReactDOM.render(<RosterTable />, document.getElementById("root"));
