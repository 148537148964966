import {
  GET_CATALOGS_SUCCESS,
  GET_CATALOGS_FAIL,
  GET_COURSES_SUCCESS,
  GET_COURSES_FAIL,
  CATALOG_SELECT_SUCCESS,
  COURSE_SELECT_SUCCESS,
  SESSION_SELECT_SUCCESS,
  EVENT_SELECT_SUCCESS,
  CLEAN_COURSE_QUERY_SEARCH,
  START_COURSE_SEARCH,
  FINISH_COURSE_SEARCH,
  UPDATE_COURSE_SELECTION,
  CLEAN_CATALOG_QUERY_SEARCH,
  START_CATALOG_SEARCH,
  FINISH_CATALOG_SEARCH,
  UPDATE_CATALOG_SELECTION,
} from "../Types/types";

//use local storage to persist the currently selected course
//this could be moved to react.persist feature later
//this is needed to ensure that page doesn't fail if 
//page is reloaded and app needs course_id
const persistedCatalog = JSON.parse(localStorage.getItem("currentCatalog"));
const persistedCourse = JSON.parse(localStorage.getItem("currentCourse"));

const initialState = {
  catalogs: null,
  catalog: persistedCatalog ? persistedCatalog : null,
  courses: null,
  course: persistedCourse ? persistedCourse : null,
};

export default function coursesReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CATALOGS_SUCCESS:
      return {
        ...state,
        catalogs: payload.catalogs,
      };
    case GET_CATALOGS_FAIL:
      return {
        ...state,
        catalogs: null,
      };
    case GET_COURSES_SUCCESS:
      return {
        ...state,
        courses: payload.courses,
      };
    case GET_COURSES_FAIL:
      return {
        ...state,
        courses: null,
      };
      case CATALOG_SELECT_SUCCESS:
      return {
        ...state,
        catalog: payload.catalog,
      };
    case COURSE_SELECT_SUCCESS:
      return {
        ...state,
        course: payload.course,
      };
    case SESSION_SELECT_SUCCESS:
      return {
        ...state,
        session: payload.session,
      };
    case EVENT_SELECT_SUCCESS:
      return {
        ...state,
        event: payload.event,
      };
      case CLEAN_CATALOG_QUERY_SEARCH:
        return initialState;
      case START_CATALOG_SEARCH:
        return {
          ...state,
          loading: true,
          value: payload.query,
        };
      case FINISH_CATALOG_SEARCH:
        return {
          ...state,
          loading: false,
          results: payload.results,
        };
      case UPDATE_CATALOG_SELECTION:
        return {
          ...state,
          value: payload.selection,
        };
    case CLEAN_COURSE_QUERY_SEARCH:
      return initialState;
    case START_COURSE_SEARCH:
      return {
        ...state,
        loading: true,
        value: payload.query,
      };
    case FINISH_COURSE_SEARCH:
      return {
        ...state,
        loading: false,
        results: payload.results,
      };
    case UPDATE_COURSE_SELECTION:
      return {
        ...state,
        value: payload.selection,
      };
    default:
      return state;
  }
}
