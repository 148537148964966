import React, { useState, useEffect } from "react";
import DoceboScheduledTraining from "./doceboScheduledTraining";
import SchooxAdHocTranscripts from "./schooxAdhocTranscripts";
import OraclelearnScheduledTraining from "./oraclelearnScheduledTraining";
import { useSelector } from "react-redux";

import { Tab } from "semantic-ui-react";

const SelectRosterTabs = () => {
  let panes = [];
  // clear/reset selected course
  localStorage.removeItem("currentCourse");

  // init user view
  const user = useSelector((state) => state.auth);

  if (user && user.user && user.user.tabs) {
    console.log("Get Tabs Info: " + JSON.stringify(user.user.tabs));
    //console.log('Get Tabs Info PARSE: ' + JSON.stringify(JSON.parse(user.user.tabs)));
    var userTabsArray = JSON.parse(user.user.tabs);
    console.log(userTabsArray);
    if (userTabsArray) {
      var uiTabs = userTabsArray.tabs;
      if (uiTabs && uiTabs.length > 0) {
        for (var tb in uiTabs) {
          console.log(
            "uiTabs: " + uiTabs[tb].component + " - " + uiTabs[tb].name
          );
          if (uiTabs[tb].component === "DoceboScheduledTraining") {
            panes.push({
              menuItem: uiTabs[tb].name,
              render: () => (
                <Tab.Pane>
                  <DoceboScheduledTraining />
                </Tab.Pane>
              ),
            });
          }
          if (uiTabs[tb].component === "SchooxAdHocTranscripts") {
            panes.push({
              menuItem: uiTabs[tb].name,
              render: () => (
                <Tab.Pane>
                  <SchooxAdHocTranscripts />
                </Tab.Pane>
              ),
            });
          }
          if (uiTabs[tb].component === "OraclelearnScheduledTraining") {
            panes.push({
              menuItem: uiTabs[tb].name,
              render: () => (
                <Tab.Pane>
                  <OraclelearnScheduledTraining />
                </Tab.Pane>
              ),
            });
          }
        }
      }
    }
  } else {
    panes = [
      {
        menuItem: "Default",
        render: () => (
          <Tab.Pane>You are not assigned to any Tabs in RapidRoster</Tab.Pane>
        ),
      },
    ];
  }

  const [key, setKey] = useState(0);

  const handleTabChange = (e, { activeIndex }) => setKey({ activeIndex });

  return <Tab panes={panes} defaultActiveIndex={key} />;
};

export default SelectRosterTabs;
