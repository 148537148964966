import React from 'react';
import { Button } from "semantic-ui-react";

const DownloadCSV = ({ data, fileName }) => {
  const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    for (let i = 0; i < array.length; i++) {
      str += array[i] + '\r\n';
    }
    return str;
  };

  const removeDuplicates = (arr) => {
    let unique = arr.reduce(function (acc, curr) {
        if (!acc.includes(curr))
            acc.push(curr);
        return acc;
    }, []);
    return unique;
  }

  const downloadCSV = () => {
    const csvData = new Blob([convertToCSV(removeDuplicates(data))], { type: 'text/csv' });
    const csvURL = URL.createObjectURL(csvData);
    const link = document.createElement('a');
    link.href = csvURL;
    link.download = `${fileName}.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Button variant="primary" onClick={downloadCSV}>Download Completion Report</Button>
  );
}

export default DownloadCSV;