import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Form } from "react-bootstrap";
import { getLMSCatalogs, getLMSCourses, catalogSelect, courseSelect, sessionSelect, eventSelect } from "../../Redux/Actions/coursesAction";
import { genericOfferings } from "../../Webservices/genericApiTransposer";
import axios from "axios";
import CourseSessionEventModal from "./courseSessionEventModal";
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './../../config';

function DodeboScheduledTraining() {
  const [catalogLoading, setCatalogLoading] = useState(true);
  const [courseLoading, setCourseLoading] = useState(true);
  const [sessionLoading, setSessionLoading] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [catalogs, setCatalogs] = useState([]);
  const [catalog, setCatalog] = useState({});
  const [courses, setCourses] = useState([]);
  const [course, setCourse] = useState({});
  const [sessions, setSessions] = useState([]);
  const [session, setSession] = useState({});
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState({});
  const [disableConfirm, setDisableConfirm] = useState(true);

  const navigate= useNavigate();

  // this baseApiUrl needs to be a constant and set globally
  //let baseApiUrl = "http://rlddocebo.localhost:8080/v1";
  let baseApiUrl = global.config.baseApiUrl;
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { catalogs: currentCatalogs, catalog: currentCatalog, courses: currentCourses, course: currentCourse, session: currentSession, event: currentEvent } = useSelector(state => state.courses);
  const { user: currentUser } = useSelector(state => state.auth);
  const dispatch = useDispatch(); 

  const useHasChanged= (val) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
  }
  
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  
  useEffect(() => {
    //getSchooxCourses();
    setDisableConfirm(true);

    return () => {
      //unmounted = true;
    };
  }, []);

  const hasCatalogChanged = useHasChanged(catalog);
  const hasCourseChanged = useHasChanged(course);
  const hasSessionChanged = useHasChanged(session);
  
  // Get Catalogs
  useEffect(() => {
    let unmounted = false;

    async function getDoceboCatalogs() {
      var failed = false;

      let url = baseApiUrl + "/courses?name=catalog";
      console.log('getDoceboCatalogs: url: ' + url);

      let reqBody = {"userId": currentUser.id, "accessToken": currentUser.accessToken};

      let data = await axios.post(url, reqBody);
      console.log('The Catalogs are: ' + JSON.stringify(data));

      dispatch(getLMSCatalogs(data, failed));
      const catalogs_initial = [{ label: "Select a Course Catalog", value: "Dummy",
                                 selectedCatalog: {} }];
      const catalogs = data.data.catalogs.map(({ catalog_id, catalog_name }) => ({ label: catalog_id + " - " + 
                          catalog_name, 
                          value: catalog_id,
                          selectedCatalog: {"catalog_id": JSON.stringify(catalog_id), 
                                            "catalog_name": catalog_name
                                           } 
                          }))
      const catalogs_array = [...catalogs_initial, ...catalogs];
      console.log("catalogs array: " + JSON.stringify(catalogs_array));

      if (!unmounted) {
        setCatalogs(catalogs_array);
        setCatalogLoading(false);
    }
    localStorage.removeItem("currentCatalog");
    localStorage.removeItem("currentCourse");
    localStorage.removeItem("currentSession");
    localStorage.removeItem("currentEvent");

    setDisableConfirm(true);

    setCourses([]);
    setSessions([]);
    setEvents([]);

    if (courses) {
      setCourseLoading(true);
    }

    if (sessions) {
      setSessionLoading(true);
    }

    if (events) {
      setEventLoading(true);
    }

  }

  getDoceboCatalogs();
  
  return () => {
    unmounted = true;
  };
}, []);

// Get Courses
useEffect(() => {
  let unmounted = false;

  async function getDoceboCourses() {
    var failed = false;

    let url = baseApiUrl + "/courses?name=catalog_course";
    console.log('getDoceboCourses: url: ' + url);

    console.log('getDoceboCourses: currentUser: ' + JSON.stringify(currentUser));
    console.log('getDoceboCourses: currentCatalog: ' + JSON.stringify(currentCatalog));

    let catalogId = "";

    if (currentCatalog) {
      catalogId = currentCatalog.catalog_id;
      let instructorId = currentUser.id;

      let reqBody = {"catalogId": catalogId, "instructorId": instructorId, "accessToken": currentUser.accessToken};

      let data = await axios.post(url, reqBody);
      console.log('The Courses getCoursesBasedOnCatalog() are: ' + JSON.stringify(data));

      dispatch(getLMSCourses(data, failed));
      const courses_initial = [{ label: "Select a ILT Course", value: "Dummy",
                                  selectedCourse: {} }];
      let courses = []; 
      if (data.data.courses != null && data.data.courses.length > 0) {
          courses = data.data.courses.map(({ course_id, course_name, course_type }) => ({ label: course_id + " - " + 
                      course_name + " - " + course_type, 
                      value: course_id,
                      selectedCourse: {"course_id": course_id, 
                                       "course_name": course_name,
                                       "course_type": course_type
                                      } 
                                  }))
      }
      const courses_array = [...courses_initial, ...courses];
      console.log("courses array: " + JSON.stringify(courses_array));
      
      if (!unmounted) {
        console.log("courses unmounted: " + unmounted);
        setCourses(courses_array);
        if (courses_array && courses_array.length > 1) {
          console.log("courses mounted!!!");
          setCourseLoading(false);
          setSessionLoading(true);
          setEventLoading(true);
        } else {
          setCourseLoading(true);
        }
      }
    }
  setDisableConfirm(true);

  setSessions([]);
  setEvents([]);

  if (sessions) {
    setSessionLoading(true);
  }

  if (events) {
    setEventLoading(true);
  }

}
if (hasCatalogChanged) {
  getDoceboCourses();
}


return () => {
  unmounted = true;
};
}, [catalog]);

// Get Sessions - Events
useEffect(() => {
  let unmounted = false;

  async function getDoceboSessions() {
    var failed = false;

    let url = baseApiUrl + "/sessions";
    console.log('getDoceboSessions: url: ' + url);

    console.log('getDoceboSessions: currentUser: ' + JSON.stringify(currentUser));
    console.log('getDoceboSessions: currentCourse: ' + JSON.stringify(currentCourse));
    console.log('getDoceboSessions: selectedCourse: ' + JSON.stringify(course));

    let courseId = "";
    if (currentCourse && course && Object.keys(course).length > 0 
            && Object.keys(currentCourse).length > 0) {
      courseId = currentCourse.course_id;
   
      let instructorId = currentUser.id;

      let reqBody = {"courseId": courseId, "instructorId": instructorId, "accessToken": currentUser.accessToken};

      let data = await axios.post(url, reqBody);
      console.log('The Sessions are: ' + JSON.stringify(data));

      const sessions_initial = [{ label: "Select a Classroom Session", value: "Dummy", selectedSession: {} }];
      const sessions = data.data.sessions.map(({ session_id, session_name, course_id, session_date_begin, session_date_end }) => ({ label: "(" + course_id + ") " + session_id + " - " + session_name 
                  + " - " + session_date_begin + " - " + session_date_end,  
                  value: session_id,
                  selectedSession: {
                      "session_id": session_id,
                      "session_name": session_name,
                      "session_date_begin": session_date_begin,
                      "session_date_end": session_date_end
                    }
                }))
      const sessions_array = [...sessions_initial, ...sessions];
      
      setEvents([]);
      if (!unmounted) {
        console.log("sessions unmounted: " + unmounted);
        setSessions(sessions_array);
        console.log("sessions_array unmounted: " + JSON.stringify(sessions_array));
        setSessionLoading(false);
      }
    }
    setDisableConfirm(true);
  }

  async function getDoceboEvents() {
    var failed = false;

    let url = baseApiUrl + "/events";
    console.log('getDoceboEvents: url: ' + url);

    let sessionId = "";
    if (currentSession) {
      sessionId = JSON.stringify(currentSession.session_id);
      let reqBody = {"sessionId": sessionId, "accessToken": currentUser.accessToken};

      let data = await axios.post(url, reqBody);
      console.log('The Events are: ' + JSON.stringify(data));

      const events_initial = [{ label: "Select a Classroom Event", value: "Dummy", selectedEvent: {} }];
      const events = data.data.events.map(({ event_id, event_name, session_id, event_date, 
                event_time, event_venue, session_score_base, session_evaluation_type, session_min_attended_events_for_completion }) => ({ label: "(" + session_id + ") " + 
                event_id + " - " + event_name + " - " + event_date + " - " + 
                event_time + " - " + event_venue, 
                value: event_id,
                selectedEvent: {
                    "event_id": event_id,
                    "event_name": event_name,
                    "event_date": event_date,
                    "event_time": event_time,
                    "event_venue": event_venue,
                    "session_score_base": session_score_base,
                    "session_evaluation_type": session_evaluation_type,
                    "session_min_attended_events_for_completion": session_min_attended_events_for_completion
                } 
              }))
      const events_array = [...events_initial, ...events];

      if (!unmounted) {
        console.log("events unmounted: " + unmounted);
        setEvents(events_array);
        setEventLoading(false);
      }
    }
    setDisableConfirm(true);
  }

  if (hasCourseChanged) {
    getDoceboSessions();
  } else if (hasSessionChanged) {
    getDoceboEvents();
  }

  return () => {
    unmounted = true;
  };
}, [course, session]);

const handleCatalogChange = (e) => {
  console.log('catalogs: ' + JSON.stringify(catalogs));
  console.log('catalog selected: ' + e.currentTarget.value);
  var selectedCatalog = catalogs.filter(catalog=>catalog.value==e.currentTarget.value);
  dispatch(catalogSelect(selectedCatalog[0].selectedCatalog));
  console.log('catalog: ' + JSON.stringify(selectedCatalog[0]));
  setCatalog(JSON.stringify(selectedCatalog[0].value));
  setCourse({});
  setSession({});
  setEvent({});
  console.log('Catalog Id: ' + JSON.stringify(selectedCatalog[0].value));
  setDisableConfirm(true);
}

const handleCourseChange = (e) => {
  console.log('courses: ' + JSON.stringify(courses));
  console.log('course selected: ' + e.currentTarget.value);
  var selectedCourse = courses.filter(course=>course.value==e.currentTarget.value);
  console.log('selectedCourse: ' + JSON.stringify(selectedCourse));
  console.log('selectedCourse: ' + JSON.stringify(selectedCourse[0]));
  dispatch(courseSelect(selectedCourse[0].selectedCourse));
  console.log('course: ' + JSON.stringify(selectedCourse[0]));
  setCourse(e.currentTarget.value);
  setSession({});
  setEvent({});
  console.log('Course Id: ' + JSON.stringify(selectedCourse[0].value));
  if (course && catalog) {
    setDisableConfirm(false);
  } else {
    setDisableConfirm(true);
  }
}


const handleSessionChange = (e) => {
  console.log('sessions: ' + JSON.stringify(sessions));
  var selectedSession = sessions.filter(session=>session.value==e.currentTarget.value);
  dispatch(sessionSelect(selectedSession[0].selectedSession));
  console.log('session: ' + JSON.stringify(selectedSession[0]));
  setSession(JSON.stringify(selectedSession[0].value));
  setEvent({});
  console.log('Session Id: ' + JSON.stringify(selectedSession[0].value));
  if (session && course && catalog) {
    setDisableConfirm(false);
  } else {
    setDisableConfirm(true);
  }
}

const handleEventChange = (e) => {
  console.log('events: ' + JSON.stringify(events));
  var selectedEvent = events.filter(event=>event.value==e.currentTarget.value);
  dispatch(eventSelect(selectedEvent[0].selectedEvent));
  setEvent(selectedEvent[0].value);
  console.log('Event Id: ' + JSON.stringify(selectedEvent[0].value));

  if (event && session && course && catalog) {
    setDisableConfirm(false);
  } else {
    setDisableConfirm(true);
  }
}

const onCourseSessionEventFormSubmit = (e) => {
  e.preventDefault();
  handleClose();
};

const handleSubmit = (e) => {
  console.log("Inside handleSubmit");
  e.preventDefault();
 // history.push('/manageRoster');
 navigate("/manageRoster", {replace:true});
 // return <Navigate to="/manageRoster" replace={true}/>;
}

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled ? 'red' : 'blue',
      color: '#FFF',
      cursor: isDisabled ? 'not-allowed' : 'default'
    };
  },
};

  return (
    <>
      <div className="col-md-12">
        <div className="card card-container">
          <label><strong>Select Course Catalog:</strong></label>
            
            <select
              disabled={catalogLoading}
              value={catalog}
              onChange={(e) => handleCatalogChange(e)}
              styles={colourStyles}
            >
            {catalogs.map(({ label, value }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
            </select>
          
            <br />
          
            <label><strong>Select Course:</strong></label>
            
              <select
                disabled={courseLoading}
                value={course}
                onChange={(e) => handleCourseChange(e)}
                styles={colourStyles}
              >
              {courses.map(({ label, value }) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
              </select>
            
            <br />
            
            <label><strong>Select Session:</strong></label>
            <select
              disabled={sessionLoading}
              value={session}
              onChange={(e) => handleSessionChange(e)}
            >
            {sessions.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
            ))}
            </select>
            <br />
            <label><strong>Select Event:</strong></label>
            <select
              disabled={eventLoading}
              value={event}
              onChange={(e) => handleEventChange(e)}
            >
            {events.map(({ label, value }) => (
            <option key={value} value={value}>
              {label}
            </option>
            ))}
            </select>
          </div>
    </div>

    <br />
    <br />
    <div className="d-flex align-items-center justify-content-center">
        <Button variant="primary" onClick={handleShow} disabled={disableConfirm}>
          Confirm
        </Button>
    </div>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CourseSessionEventModal onSubmit={onCourseSessionEventFormSubmit} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
    </Modal>

  </>
  );
}

export default DodeboScheduledTraining;