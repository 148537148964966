import axios from "axios";
import './../config';

// this baseApiUrl needs to be a constant and set globally
//const protocol = process.env.REACT_APP_API_PROTOCOL;
//const port = process.env.REACT_APP_API_PORT ? `:${process.env.REACT_APP_API_PORT}` : '';
//const host = process.env.REACT_APP_API_HOST;
//let baseApiUrl = `${protocol}rlddocebo.${host}${port}/v1`;//"http://rlddocebo.localhost:8080/v1";

let baseApiUrl = global.config.baseApiUrl;



export function checkSsoEnabled(reqBody) {
  let url = baseApiUrl + "/login/config";
  //console.log('checkSsoEnabled: url: ' + url + " reqBody: " + JSON.stringify(reqBody));

  return axios.post(url, reqBody).then((response) => {
      //console.log("RESPONSE IS: " + JSON.stringify(response));
      return response.data;
  });

  //return getAnonymousFetch(url, reqBody);
}

export function authLogin(reqBody) {
  let url = baseApiUrl + "/login";
  //console.log('authLogin: url: ' + url + " reqBody: " + JSON.stringify(reqBody));
  console.log('authLogin: url: ' + url);
  
  return axios.post(url, reqBody).then((response) => {
      console.log("RESPONSE IS: " + JSON.stringify(response));
      return response.data;
  });

  //return getAnonymousFetch(url, reqBody);
}

export function getCatalogs(reqBody) {
  let url = baseApiUrl + "/coursecatalog";
  console.log('getCatalogs: url: ' + url);

  return axios.post(url, reqBody).then((response) => {
      console.log("getCatalogs RESPONSE IS: " + JSON.stringify(response));
      return response;
  });
}

export function getCourses(reqBody) {
  let url = baseApiUrl + "/courses";
  console.log('getCourses: url: ' + url);

  return axios.post(url, reqBody).then((response) => {
      console.log("getCourses RESPONSE IS: " + JSON.stringify(response));
      return response;
  });

  //return getAnonymousFetch(url, reqBody);
}

export function getSessions(reqBody) {
  let url = baseApiUrl + "/sessions";
  console.log('getSessions: url: ' + url);

  return axios.post(url, reqBody).then((response) => {
      console.log("getSessions RESPONSE IS: " + JSON.stringify(response));
      return response;
  });

  //return getAnonymousFetch(url, reqBody);
}

export function getEvents(reqBody) {
  let url = baseApiUrl + "/events";
  console.log('getEvents: url: ' + url);

  return axios.post(url, reqBody).then((response) => {
      console.log("getEvents RESPONSE IS: " + JSON.stringify(response));
      return response;
  });

  //return getAnonymousFetch(url, reqBody);
}

export function checkinLearners(reqBody) {
  let url = baseApiUrl + "/learner/checkin";
  console.log('checkinLearners: url: ' + url);
  console.log('checkinLearners: reqBody: ' + JSON.stringify(reqBody));

  return axios.post(url, reqBody).then((response) => {
      console.log("checkinLearners RESPONSE IS: " + JSON.stringify(response));
      return response;
  });
}

export function cancelCheckInLearners(reqBody) {
  let url = baseApiUrl + "/learner/check";
  console.log('cancelCheckInLearners: url: ' + url);
  console.log('cancelCheckInLearners: reqBody: ' + JSON.stringify(reqBody));

  return axios.post(url, reqBody).then((response) => {
      console.log("cancelCheckInLearners RESPONSE IS: " + JSON.stringify(response));
      return response;
  });
}

export function markAttendanceLearners(reqBody) {
  let url = baseApiUrl + "/learner/check";
  console.log('markAttendanceLearners: url: ' + url);
  console.log('markAttendanceLearners: reqBody: ' + JSON.stringify(reqBody));

  return axios.post(url, reqBody).then((response) => {
      console.log("markAttendanceLearners RESPONSE IS: " + JSON.stringify(response));
      return response;
  });
}

export function markCompletionLearners(reqBody) {
  let url = baseApiUrl + "/learner/check";
  console.log('markCompletionLearners: url: ' + url);
  console.log('markCompletionLearners: reqBody: ' + JSON.stringify(reqBody));

  return axios.post(url, reqBody).then((response) => {
      console.log("markCompletionLearners RESPONSE IS: " + JSON.stringify(response));
      return response;
  });
}

export function markCourseCompletionLearners(reqBody) {
  let url = baseApiUrl + "/learner/complete";
  console.log('markCourseCompletionLearners: url: ' + url);
  console.log('markCourseCompletionLearners: reqBody: ' + JSON.stringify(reqBody));

  return axios.post(url, reqBody).then((response) => {
      console.log("markCourseCompletionLearners RESPONSE IS: " + JSON.stringify(response));
      return response;
  });
}

// ======= Local methods =========
function getAnonymousFetch(url, reqBody) {
  let myHeaders = new Headers();
  var failed = false;
  myHeaders.append("Content-Type", "application/json");
  let request = fetch(url, {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(reqBody)
  });
  if (!request.ok) {
    console.log("RestMethodsToNode: REQUEST: " + request.status);
    if (request.status >= 400 && request.status < 600) {
      failed = true;
    }
  }
  let data = request.json();
  //console.log("RestMethodsToNode: RESPONSE:");
  //console.log(JSON.stringify(data));
  if (failed) {
    throw new Error(data.errorMessage);
  }

  return data;
}

async function doAnonymousFetch(method, url, body) {
  let myHeaders = new Headers();
  var failed = false;
  myHeaders.append("Content-Type", "application/json");
  let request = await fetch(url, {
    method: method,
    body: JSON.stringify(body),
    headers: myHeaders,
  });
  if (!request.ok) {
    console.log("RestMethodsToNode: REQUEST: " + request.status);
    if (request.status >= 400 && request.status < 600) {
      failed = true;
    }
  }
  let data = await request.json();
  console.log("RestMethodsToNode: RESPONSE:");
  console.log(JSON.stringify(data));
  if (failed) {
    throw new Error(data.errorMessage);
  }

  return data;
}
