import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SelectRoster from "../../SelectRoster/containers/selectRoster";

const Profile = () => {
    const { user: currentUser } = useSelector(state => state.auth);

    if (!currentUser) {
        return <Navigate to="/login" />;
    }

    return (
        <div className="container">
            <SelectRoster />
        </div>
    );
};

export default Profile;