import React, { useState, Suspense, useEffect, useRef } from "react";

const baseApiUrl = global.config.baseApiUrl;
const baseSsoUrl = global.config.baseSsoUrl;

/**
 * @typedef SSOLoginProps
 * @property {function} authCallback    Callback function when authentication is complete. 
 *                                      called with err and authData
 */
/**
 * 
 * @param {SSOLoginProps} props 
 * @returns 
 */
const SSOLogin = (props) => {
    let childWindow;

    const onMessage = (event)=>{
        if(!baseSsoUrl.startsWith(event.origin)){
            throw new Error('Invalid event origin - ' + event.origin);
        }else if(!event.isTrusted){
            throw new Error('Untrusted event');
        }

        window.removeEventListener('message', onMessage);
        if(event.source) event.source.close();

        let authData;
        let err;
        try{
            authData = JSON.parse(decodeURIComponent(event.data));
        }catch(e){
            err="Unable to parse event data";
        }
        props.authCallback(err, authData);

    }
    const handleClick = ()=>{
        const protocol = window.location.protocol || "https:";
        const host = window.location.host;
        const baseLoginUrl = "/login";
        //encoding relayState in prep for supporting IdP initiated session
        const relayState = encodeURIComponent('message');//`${protocol}//${host}${baseLoginUrl}`
        //window.location.href = `${baseSsoUrl}?RelayState=${encodeURIComponent(relayStateUrl)}`;
        childWindow = window.open(`${baseSsoUrl}?RelayState=${relayState}`, "sso", "width=800;height=600");
        window.addEventListener("message", onMessage);
    }

    return (
        <div style={{cursor: "pointer"}}  onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="215" height="41" >
        <path fill="#ffffff" d="M0 0h215v41H0z"></path>
        <path fill="#8c8c8c" d="M214 1v39H1V1h213m1-1H0v41h215V0z"></path>
        
        </svg>
            <div style={{position: "relative", top: "-30px", left: "-10px"}}>Single&nbsp;Sign&nbsp;On</div>
        </div>
    )
}

export default SSOLogin;