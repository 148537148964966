import {
    GET_ENROLLMENTS_SUCCESS,
    GET_ENROLLMENTS_FAIL,
    SELECT_USER_SUCCESS,
} from "../Types/types";

const initialState = { enrollments: null, user: null };

export default function enrollmentsReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_ENROLLMENTS_SUCCESS:
            return {
                ...state,
                enrollments: payload.enrollments,
            };
        case GET_ENROLLMENTS_FAIL:
            return {
                ...state,
                enrollments: null,
            };
        case SELECT_USER_SUCCESS:
            return {
                ...state,
                user: payload.user,
            };
        default:
            return state;
    }
}