import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { useDispatch, useSelector } from "react-redux";

const CourseOfferingActivityModal = ({ onSubmit }) => {
  const [course, setCourse] = useState({});
  const [session, setSession] = useState({});
  const [event, setEvent] = useState({});
  
  const { course: currentCourse, session: currentSession, event: currentEvent } = useSelector((state) => state.courses);
  
  return (
    <Form onSubmit={onSubmit}>
      <Form.Label><b>Course Details</b></Form.Label>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Course Number</b></InputGroup.Text>
          <InputGroup.Text>{currentCourse.course_number}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Course Name</b></InputGroup.Text>
          <InputGroup.Text>{currentCourse.course_name}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Course Description</b></InputGroup.Text>
          <InputGroup.Text>{currentCourse.course_description}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <hr />
      <Form.Label><b>Offering Details</b></Form.Label>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Offering Number</b></InputGroup.Text>
          <InputGroup.Text>{currentSession.offering_number}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Offering Name</b></InputGroup.Text>
          <InputGroup.Text>{currentSession.offering_name}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Offering Description</b></InputGroup.Text>
          <InputGroup.Text>{currentSession.offering_description}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Offering Capacity</b></InputGroup.Text>
          <InputGroup.Text>{currentSession.minCapacity} - {currentSession.maxCapacity}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <hr />
      <Form.Label><b>Activity Details</b></Form.Label>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Activity Number</b></InputGroup.Text>
          <InputGroup.Text>{currentEvent.activity_number}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Activity Name</b></InputGroup.Text>
          <InputGroup.Text>{currentEvent.activity_name}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Activity Date</b></InputGroup.Text>
          <InputGroup.Text>{currentEvent.activity_date} - {currentEvent.activity_time}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      {currentEvent.activity_venue.length < 50 ?
        <InputGroup className="mb-1">
          <InputGroup.Prepend>
            <InputGroup.Text><b>Activity Venue</b></InputGroup.Text>
            <InputGroup.Text>{currentEvent.activity_venue}</InputGroup.Text>
          </InputGroup.Prepend>
        </InputGroup>
      : <InputGroup className="mb-1">
          <InputGroup.Prepend>
            <InputGroup.Text><b>Activity Venue</b></InputGroup.Text>
            <InputGroup.Text>{currentEvent.activity_venue.substring(0,50)}</InputGroup.Text>
          </InputGroup.Prepend>
          <InputGroup.Prepend>
            <InputGroup.Text><b></b></InputGroup.Text>
            <InputGroup.Text>{currentEvent.activity_venue.substring(50)}</InputGroup.Text>
          </InputGroup.Prepend>
        </InputGroup>
      }
    </Form>
  );
};

export default CourseOfferingActivityModal;