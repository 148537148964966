export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// Catalog
export const GET_CATALOGS_SUCCESS = "GET_CATALOGS_SUCCESS";
export const GET_CATALOGS_FAIL = "GET_CATALOGS_FAIL";

// Courses
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";
export const GET_COURSES_FAIL = "GET_COURSES_FAIL";

// Sessions
export const GET_SESSIONS_SUCCESS = "GET_SESSIONS_SUCCESS";
export const GET_SESSIONS_FAIL = "GET_SESSIONS_FAIL";

// Events
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

export const CATALOG_SELECT_SUCCESS = "CATALOG_SELECT_SUCCESS";
export const COURSE_SELECT_SUCCESS = "COURSE_SELECT_SUCCESS";
export const SESSION_SELECT_SUCCESS = "SESSION_SELECT_SUCCESS";
export const EVENT_SELECT_SUCCESS = "EVENT_SELECT_SUCCESS";

export const GET_ENROLLMENTS_SUCCESS = "GET_ENROLLMENTS_SUCCESS";
export const GET_ENROLLMENTS_FAIL = "GET_ENROLLMENTS_FAIL";

export const SELECT_USER_SUCCESS = "SELECT_USER_SUCCESS";
export const SELECT_USER_FAIL = "SELECT_USER_FAIL";

export const SELECT_USERS_SUCCESS = "SELECT_USERS_SUCCESS";
export const SELECT_USERS_FAIL = "SELECT_USERS_FAIL";

export const CLEAN_COURSE_QUERY_SEARCH = "CLEAN_COURSE_QUERY_SEARCH";
export const START_COURSE_SEARCH = "START_COURSE_SEARCH";
export const FINISH_COURSE_SEARCH = "FINISH_COURSE_SEARCH";
export const UPDATE_COURSE_SELECTION = "UPDATE_COURSE_SELECTION";

export const CLEAN_CATALOG_QUERY_SEARCH = "CLEAN_CATALOG_QUERY_SEARCH";
export const START_CATALOG_SEARCH = "START_CATALOG_SEARCH";
export const FINISH_CATALOG_SEARCH = "FINISH_CATALOG_SEARCH";
export const UPDATE_CATALOG_SELECTION = "UPDATE_CATALOG_SELECTION";