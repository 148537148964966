import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_MESSAGE } from "../Types/types";

import {
  genericCheckSsoEnabled,
  genericAuthLogin,
  genericAuthLogout,
} from "../../Webservices/genericApiTransposer";

export const checkSsoStatus = (companyId) => (dispatch) => {
  let reqObject = {
    companyId: companyId,
  };

  return genericCheckSsoEnabled(reqObject).then(
    (ssoInfo) => {
      return ssoInfo;
    },
    (error) => {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return Promise.reject();
    }
  );
};
export const ssoUserLoggedIn = (userInfo=[]) => (dispatch) =>{
  let user = {};
  userInfo.forEach(item=>{
    user = {...user, ...item}
  })

  localStorage.setItem("user", JSON.stringify(user));
  dispatch({type: LOGIN_SUCCESS, payload: {user}})
  return Promise.resolve(user);
}
export const login = (companyId, username, password, msalInstance) => (dispatch) => {
  let searchObject = {};
  searchObject.companyId = companyId;
  searchObject.username = username;
  searchObject.password = password;
  searchObject.userid = username;

  return genericAuthLogin(searchObject).then(
    (data) => {
      console.log("action data: " + JSON.stringify(data));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data, msalInstance: msalInstance },
      });

      //return Promise.resolve();
      return data;
    },
    (error) => {
      const message =
        (error.message && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
        console.log("action message: " + JSON.stringify(message));

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  genericAuthLogout();

  dispatch({
    type: LOGOUT,
  });
};
