import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
//import { Form, Input } from 'semantic-ui-react';
//import "./styles.css";
import "bootstrap/dist/css/bootstrap.css";
import { useDispatch, useSelector } from "react-redux";

const CourseModal = ({ onSubmit }) => {
  const [course, setCourse] = useState({});
  
  const { course: currentCourse } = useSelector((state) => state.courses);
  
  return (
    <Form onSubmit={onSubmit}>
      <Form.Label column="lg"><b>Course Details</b></Form.Label>
      <Form.Label column="sm"></Form.Label>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Course Id</b></InputGroup.Text>
          <InputGroup.Text>{currentCourse.course_id}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Course name</b></InputGroup.Text>
          <InputGroup.Text>{currentCourse.course_name}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Course Level</b></InputGroup.Text>
          <InputGroup.Text>{currentCourse.course_level}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
    </Form>
  );
};

export default CourseModal;