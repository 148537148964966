import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../Redux/Actions/enrollmentsAction";
import {
  Message
} from "semantic-ui-react";
import $ from "jquery";
import "./user.css";

const UsersModal = ({ onSubmit, userInfo }) => {
  const [name, setName] = useState("");
  console.log("UserInfo is: " + JSON.stringify(userInfo));
  const dispatch = useDispatch();
  const [users, setUsers] = useState(userInfo);
  const [user, setUser] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  $(".table tbody tr td").click(function (event) {
    if (event.target.type !== "radio") {
      $(":radio", this).trigger("click");
    }
  });

  $(function () {
    $('input[name="radios"]:radio').change(function () {
      var userId = $("input[name='radios']:checked").val();
      //alert($("input[name='radios']:checked").val());
      setUser(userId);
      setName(name);
      console.log("userId: " + JSON.stringify(userId));
      $("#textboxes").show();
    });
  });

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
    console.log("name: " + JSON.stringify(name));
    dispatch(selectUser(name));
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="table-responsive">
        {userInfo && userInfo.length > 0 ? (
          <table className="table table-hover">
            <thead>
              <tr>
                <th></th>
                <th>User Id</th>
                <th>Username</th>
                <th>Name</th>
                <th>Email</th>
                <th>Manager</th>
              </tr>
            </thead>
            <tbody>
              {userInfo.map((user, index) => (
                <tr id={index} key={`rows_${index}`}>
                  <td>
                    <input
                      type="radio"
                      name="radios"
                      id={user.user_id}
                      value={user.user_id + ':' + user.first_name + ',' + user.last_name}
                      onClick={onChangeName}
                    />
                  </td>
                  <td>
                    {user.user_id}
                  </td>
                  <td>{user.username}</td>
                  <td>{user.first_name + " " + user.last_name}</td>
                  <td>{user.email}</td>
                  <td>{user.is_manager}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <Message warning>
            <Message.Header>No Users found.</Message.Header>
            <p> Try changing your search criteria.</p>
          </Message>
        )}
      </div>
    </form>
  );
};

export default UsersModal;
