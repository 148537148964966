import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, InputGroup } from "react-bootstrap";
//import { Form, Input } from 'semantic-ui-react';
//import "./styles.css";
import "bootstrap/dist/css/bootstrap.css";
import { useDispatch, useSelector } from "react-redux";

const CourseSessionEventModal = ({ onSubmit }) => {
  const [catalog, setCatalog] = useState({});
  const [course, setCourse] = useState({});
  const [session, setSession] = useState({});
  const [event, setEvent] = useState({});
  
  const { catalog: currentCatalog, course: currentCourse, session: currentSession, event: currentEvent } = useSelector((state) => state.courses);
  
  return (
    <Form onSubmit={onSubmit}>
      <Form.Label><b>Catalog Details</b></Form.Label>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Catalog Id</b></InputGroup.Text>
          <InputGroup.Text>{currentCatalog.catalog_id}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Catalog name</b></InputGroup.Text>
          <InputGroup.Text>{currentCatalog.catalog_name}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <hr />
      <Form.Label><b>Course Details</b></Form.Label>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Course Id</b></InputGroup.Text>
          <InputGroup.Text>{currentCourse.course_id}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Course name</b></InputGroup.Text>
          <InputGroup.Text>{currentCourse.course_name} ({currentCourse.course_type})</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <hr />
      <Form.Label><b>Session Details</b></Form.Label>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Session Id</b></InputGroup.Text>
          <InputGroup.Text>{currentSession.session_id}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Session Name</b></InputGroup.Text>
          <InputGroup.Text>{currentSession.session_name}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Session Date</b></InputGroup.Text>
          <InputGroup.Text>{currentSession.session_date_begin} - {currentSession.session_date_end}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <hr />
      <Form.Label><b>Event Details</b></Form.Label>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Event Id</b></InputGroup.Text>
          <InputGroup.Text>{currentEvent.event_id}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Event Name</b></InputGroup.Text>
          <InputGroup.Text>{currentEvent.event_name}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Event Date</b></InputGroup.Text>
          <InputGroup.Text>{currentEvent.event_date} - {currentEvent.event_time}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      {currentEvent.event_venue.length < 50 ?
        <InputGroup className="mb-1">
          <InputGroup.Prepend>
            <InputGroup.Text><b>Event Venue</b></InputGroup.Text>
            <InputGroup.Text>{currentEvent.event_venue}</InputGroup.Text>
          </InputGroup.Prepend>
        </InputGroup>
      : <InputGroup className="mb-1">
          <InputGroup.Prepend>
            <InputGroup.Text><b>Event Venue</b></InputGroup.Text>
            <InputGroup.Text>{currentEvent.event_venue.substring(0,50)}</InputGroup.Text>
          </InputGroup.Prepend>
          <InputGroup.Prepend>
            <InputGroup.Text><b></b></InputGroup.Text>
            <InputGroup.Text>{currentEvent.event_venue.substring(50)}</InputGroup.Text>
          </InputGroup.Prepend>
        </InputGroup>
      }
    </Form>
  );
};

export default CourseSessionEventModal;