import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal, Button, Form, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import { getLMSEnrollments, selectUser, selectUsers } from "../../Redux/Actions/enrollmentsAction";
import { courseSelect, sessionSelect, eventSelect } from "../../Redux/Actions/coursesAction";
import "bootstrap/dist/css/bootstrap.css";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import DoceboRosterTable from "../components/doceboRosterTable";
//import UserInfoModal from "../components/userInfoModal";
import UserInfoModal from "../components/usersModal";
import _ from "lodash";
import './../../config';
import LoadSpinner from '../../components/LoadSpinner';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ManageRoster = () => {
  const [course, setCourse] = useState({});
  const [session, setSession] = useState({});
  const [event, setEvent] = useState({});
  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [usersInfo, setUsersInfo] = useState([]);

  const [enrollmentsLoading, setEnrollmentsLoading] = useState(true);
  const [usersLoading, setUsersLoading] = useState(false);
  
  const [courseId, setCourseId] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [eventId, setEventId] = useState("");

  const [courseName, setCourseName] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [eventName, setEventName] = useState("");

  const navigate= useNavigate();

  //let baseApiUrl = "http://rlddocebo.localhost:8080/v1";
  let baseApiUrl = global.config.baseApiUrl;
  
  const { course: currentCourse, session: currentSession, event: currentEvent } = useSelector(state => state.courses);
  const { user: currentUser } = useSelector(state => state.auth);
  const { user: selectedUser, users: selectedUsers, enrollments: currentEnrollments } = useSelector(state => state.enrollments);
  
  const dispatch = useDispatch(); 

  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
    console.log('username: ' + JSON.stringify(username));
  };

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
    console.log('name: ' + JSON.stringify(name));
  };

  const handleClose = () => {
    console.log("Clicked handleClose()********");
    setDisabled(true);
    dispatch(selectUser({}));
    setName("");
    setUsername("");
    setShow(false);
  };

  //const handleClose = () => setShow(false);
  
  const [isSending, setIsSending] = useState(false);
  const isMounted = useRef(true);

  const handleBack = (e) => {
    console.log("Inside handleBack");
    e.preventDefault();
    //history.push('/selectRoster');    
    navigate("/selectRoster", { replace: true });
  }

  // set isMounted to false when we unmount the component
  //useEffect(() => {
  //  return () => {
  //    isMounted.current = false
  //  }
  //}, [])

  const handleShow = () => {
      dispatch(selectUser([]));
      setUsersInfo([]);
      // send the actual request
      let url = baseApiUrl + "/people";
      console.log('handleShow: url: ' + url);

      setUsersLoading(true);
      if (username == null || username === '') {
        setUserInfo({});
        //setUsersInfo([]);
        setUsersLoading(false);
        setShow(true);
      } else {
        // momentive badge logic
        let companyId = global.config.companyId;
        let username_modified = username;

        if (companyId.includes('momentive') || companyId.includes('rlddocebo')) {
          let regExp = /[a-z]/i;
          if(regExp.test(username_modified)){
            // if alphabets, get the prefix chars based on middle split
	          let half = Math.floor(username_modified.length / 2);
	          username_modified = username_modified.slice(0, half);
          } else {
            // if numeric, get the last 5 digits
            username_modified = username_modified.slice(-5);
          }
        }

        let reqBody = {userId: username_modified,
                       accessToken: currentUser.accessToken};

        console.log('The handleShow UserId is: ' + JSON.stringify(username_modified));

        axios.post(url, reqBody).then(
          (response) => {
            console.log("course-session-event handleShow: " + response);
            //dispatch(selectUser(username));
            console.log(
              "user info is: " + JSON.stringify(response.data.userInfo)
            );
            console.log("users is: " + JSON.stringify(response.data.users));
  
            if (response.data.userInfo && JSON.stringify(response.data.userInfo[0]) != '{}') {
              setUsersInfo(response.data.userInfo);
              dispatch(selectUser(response.data.userInfo));
            } else if (response.data.users) {
              setUsersInfo(response.data.users);
              dispatch(selectUser(response.data.users));
            } else {
              setUsersInfo([]);
            }
            setDisabled(true);
            setUsersLoading(false);
            setShow(true);
          },
          (error) => {
            dispatch(selectUsers([]));
            setDisabled(true);
            setUsersInfo([]);
            setUsersLoading(false);
            console.log(error);
          }
        );
      }
  }; // update the callback if the state changes

  const handleKeypress = (e) => {
    console.log("handleKeypress", e);
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
      console.log("enter");
      handleSearchUsers();
    }
  };

  const handleSearchUsers = () => {
    // send the actual request
    let url = baseApiUrl + "/people";
    console.log('getDoceboUserInfo: url: ' + url);

    setUsersLoading(true);
    if (name == null || name === '') {
      setUsersInfo([]);
      setUsersLoading(false);
      setShow(true);
    } else {
      let reqBody = {"search_text": name,
                     "accessToken": currentUser.accessToken};
      console.log('The search_text is: ' + JSON.stringify(name));

      axios.post(url, reqBody).then((response) => {
        console.log("People Search Response:" + JSON.stringify(response.data.users));
        //dispatch(selectUser(response.data.users));
        console.log("users info is: " + JSON.stringify(response.data.users));
        console.log("selectedUser is " + selectedUser);
        dispatch(selectUsers(response.data.users));
        setDisabled(true);
        setUsersInfo(response.data.users);

        setUsersLoading(false);
        setShow(true);
      }, (error) => {
        dispatch(selectUsers([]));
          setDisabled(true);
          setUsersInfo([]);
          setUsersLoading(false);
          console.log(error);
      });

      setShow(true);
    }
} // update the callback if the state changes

  const onUserInfoFormSubmit = (e) => {
    e.preventDefault();
    handleClose();
    handleSubmit();
  };

  function handleSubmit(e) {
    setEnrollmentsLoading(true);
    console.log("Inside handleSubmit: " + JSON.stringify(selectedUser));
    var failed = false;
    //e.preventDefault();
    // Set the UserId in the Redux
    // Check-In Learner | Mark Attendance | Completion | Walk-In Learner
    // Check Learner if in DB, also check the enrollment listings
    // Walk-In, 
    // Check-In
    // Mark Attendance
    // Mark Completion

    // send the actual request
    let url = baseApiUrl + "/learner/check";
    console.log('getDoceboLearnerCheck: url: ' + url);

    if (!username) {
      setUsername(selectedUser);
    }

    let course_id = currentCourse.course_id;
    let session_id = currentSession.session_id;
    let event_id = currentEvent.event_id;

    setCourseId(course_id);
    setSessionId(session_id);
    setEventId(event_id);

    setCourseName(currentCourse.course_name);
    setSessionName(currentSession.session_name);
    setEventName(currentEvent.event_name);

    let reqBody = {"courseId": course_id,
                     "sessionId": session_id,
                     "eventId": event_id,
                     "accessToken": currentUser.accessToken,
                     "learners": [
                      selectedUser
                  ]};
    
    handleClose();
    axios.post(url, reqBody).then(data => {
      console.log('handleSubmit:getLMSEnrollments:data: ' + JSON.stringify(data));

      if (data) {
        console.log('onUserInfoFormSubmit: data: ' + JSON.stringify(data.data.enrollments) + ' - ' + JSON.stringify(data.data.enrollments.length));
        if (data.data.enrollments && data.data.enrollments.length > 0) {
          console.log('onUserInfoFormSubmit: data.enrollments.size(): ' + data.data.enrollments.length + ' selectedUser: ' + selectedUser);
          var userObj = data.data.enrollments.find(x => x.id === selectedUser);
          console.log('onUserInfoFormSubmit: userObj: ' + JSON.stringify(userObj));
          if (userObj) {
            if (userObj.completed === "1") {
              toast.warn(userObj.name + " has already been marked Complete.", {
                position: toast.POSITION.BOTTOM_CENTER
              });
            } else {
              toast.info(userObj.name + " has been successfully Enrolled.", {
                position: toast.POSITION.TOP_CENTER
              });
            }
          } else {
            console.log('onUserInfoFormSubmit: DELAY');
          }
        }
        dispatch(getLMSEnrollments(data, failed));
      } 

      setEnrollmentsLoading(false);
    });
    
  }

  useEffect(() => {
    console.log("currentCourse", currentCourse)
    if (currentCourse) {
      setCourseId(currentCourse.course_id);
      setCourseName(currentCourse.course_name);
    }
  }, [currentCourse]);

  useEffect(() => {
    console.log("currentSession", currentSession)
    if (currentSession) {
      setSessionId(currentSession.session_id);
      setSessionName(currentSession.session_name);
    }
  }, [currentSession]);

  useEffect(() => {
    console.log("currentEvent", currentEvent)
    if (currentEvent) {
      setEventId(currentEvent.event_id);
      setEventName(currentEvent.event_name);
    }
  }, [currentEvent]);

  useEffect(() => {
    if (selectedUser) {
      setDisabled(false);
    }
  }, [selectedUser]);

  // Get Enrollment Listings
  useEffect(() => {
    let unmounted = false;

    async function getDoceboEnrollments() {
      var failed = false;
      setEnrollmentsLoading(true);

      let url = baseApiUrl + "/enrollments";
      console.log('getDoceboEnrollmentListings: url: ' + url);

      let course_id = '';
      let session_id = '';
      let event_id = '';

      let course_name = '';
      let session_name = '';
      let event_name = '';

      if (currentCourse) {
        course_id = currentCourse.course_id;
        session_id = currentSession.session_id;
        event_id = currentEvent.event_id;
        course_name = currentCourse.course_name;
        session_name = currentSession.session_name;
        event_name = currentEvent.event_name;
      }

      setCourseId(course_id);
      setSessionId(session_id);
      setEventId(event_id);

      setCourseName(course_name);
      setSessionName(session_name);
      setEventName(event_name);

      let reqBody = {"courseId": course_id,
                     "sessionId": session_id,
                     "eventId": event_id,
                     "accessToken": currentUser.accessToken};

      console.log('The Enrollment Listings reqBody: ' + JSON.stringify(reqBody));
      let data = await axios.post(url, reqBody);
      console.log('The Enrollments are: ' + JSON.stringify(data));
      if (data) {
        dispatch(getLMSEnrollments(data, failed));
      }

      //axios.post(url, reqBody).then((response) => {
      //  console.log('The Enrollment Listings are: ' + JSON.stringify(response));
      //  dispatch(getLMSEnrollments(response, failed));
      //}, (error) => {
      //  console.log("The Get Enrollment Listings Error is: " + error);
      //});
        
      if (!unmounted) {
        //setEnrollments([...enrollments, data.data.enrollments]);
        //setEnrollments(data.data.enrollments);
        setEnrollmentsLoading(false);
    }
  }

  getDoceboEnrollments();
  
  return () => {
    unmounted = true;
  };
}, []);

  return (
    <div className="container">
      <h1>Manage Roster</h1>
      <hr />
      <div>
          <Button variant="secondary" onClick={handleBack}>
            Go Back
          </Button>
      </div>
      <hr />
      <div>
      <InputGroup className="mb-1">
        <InputGroup.Prepend>
          <InputGroup.Text><b>Course</b></InputGroup.Text>
          <InputGroup.Text>{courseId} - {courseName}</InputGroup.Text>
        </InputGroup.Prepend>
        <InputGroup.Prepend>
          <InputGroup.Text><b>Session</b></InputGroup.Text>
          <InputGroup.Text>{sessionId} - {sessionName}</InputGroup.Text>
        </InputGroup.Prepend>
        <InputGroup.Prepend>
          <InputGroup.Text><b>Event</b></InputGroup.Text>
          <InputGroup.Text>{eventId} - {eventName}</InputGroup.Text>
        </InputGroup.Prepend>
      </InputGroup>
      </div>
      <hr />
      <div>
      <InputGroup className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Text>First and/or last name</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl placeholder="First and/or last name" onChange={onChangeName}
          value={name} 
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              handleSearchUsers();
            }
          }}
        />
        <InputGroup.Append>
          <Button variant="primary" onClick={handleSearchUsers}>Find Learner</Button>
        </InputGroup.Append>
      </InputGroup>
      </div>
      <div>
        <Form.Label><b>OR</b></Form.Label>
      </div>
      <div>
      <InputGroup className="mb-3">
        <FormControl
          placeholder="Employee ID or Username or Badge ID"
          aria-label="Employee ID or Username or Badge ID"
          aria-describedby="basic-addon2"
          onChange={onChangeUsername}
          value={username}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              handleShow();
            }
          }}
        />
        <InputGroup.Append>
          <Button variant="primary" onClick={handleShow}>Search</Button>
        </InputGroup.Append>
      </InputGroup>
      </div>
      <div>
        <hr />
        { enrollmentsLoading ? <LoadSpinner /> :
          <DoceboRosterTable/>
        }
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm User Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        { usersInfo === [] ? 
            <UserInfoModal onSubmit={onUserInfoFormSubmit} userInfo={userInfo} /> :
            <UserInfoModal onSubmit={onUserInfoFormSubmit} userInfo={usersInfo} />
        }
            </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="secondary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default ManageRoster;