import {
    GET_CATALOGS_SUCCESS,
    GET_CATALOGS_FAIL,
    GET_COURSES_SUCCESS,
    GET_COURSES_FAIL,
    SET_MESSAGE,
    CATALOG_SELECT_SUCCESS,
    COURSE_SELECT_SUCCESS,
    SESSION_SELECT_SUCCESS,
    EVENT_SELECT_SUCCESS,
} from "../Types/types";

export const getLMSCatalogs = (data, failed) => (dispatch) => {
    console.log("getLMSCatalogs: RESPONSE:" + JSON.stringify(data));
    if (failed) {
        dispatch({
            type: GET_CATALOGS_FAIL,
        })

        dispatch({
            type: SET_MESSAGE,
            payload: '',
        });
    } else {
        dispatch({
            type: GET_CATALOGS_SUCCESS,
            payload: { catalogs: data },
        });
    }

    return data;
};

export const getLMSCourses = (data, failed) => (dispatch) => {
    console.log("getLMSCourses: RESPONSE:" + JSON.stringify(data));
    if (failed) {
        dispatch({
            type: GET_COURSES_FAIL,
        })

        dispatch({
            type: SET_MESSAGE,
            payload: '',
        });
    } else {
        dispatch({
            type: GET_COURSES_SUCCESS,
            payload: { courses: data },
        });
    }

    return data;
};

export const catalogSelect = (data) => (dispatch) => {
    console.log("catalogSelect: RESPONSE:" + JSON.stringify(data));
    //clear local storage
    localStorage.removeItem("currentCatalog");
    dispatch({
        type: CATALOG_SELECT_SUCCESS,
        payload: { catalog: data },
    });
    
    localStorage.setItem("currentCatalog", JSON.stringify(data));
    return data;
};

export const courseSelect = (data) => (dispatch) => {
    console.log("courseSelect: RESPONSE:" + JSON.stringify(data));
    //clear local storage
    localStorage.removeItem("currentCourse");
    dispatch({
        type: COURSE_SELECT_SUCCESS,
        payload: { course: data },
    });
    
    localStorage.setItem("currentCourse", JSON.stringify(data));
    return data;
};


export const sessionSelect = (data) => (dispatch) => {
    console.log("sessionSelect: RESPONSE:" + JSON.stringify(data));
    dispatch({
        type: SESSION_SELECT_SUCCESS,
        payload: { session: data },
    });
    
    return data;
};

export const eventSelect = (data) => (dispatch) => {
    console.log("eventSelect: RESPONSE:" + JSON.stringify(data));
    dispatch({
        type: EVENT_SELECT_SUCCESS,
        payload: { event: data },
    });
    
    return data;
};

export const courseSearch = (search) => (dispatch) => {
    // invoke API Call
    
}