import React, { useState, Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate, Link } from "react-router-dom";

import rr_img from "./assets/images/logo.png";

import cf_img from "./assets/clients/costafarms.png";
import mm_img from "./assets/clients/momentive.jpg";
import ford_img from "./assets/clients/ford.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Login from "./Authentication/components/Login";
import Home from "./Authentication/components/Home";
import Profile from "./Authentication/components/Profile";
import SelectRoster from "./SelectRoster/containers/selectRoster";
import ManageRoster from "./ManageRoster/containers/manageRoster";
import ManageCourseRoster from "./ManageRoster/containers/manageCourseRoster";
import ManageOfferingActivityRoster from "./ManageRoster/containers/manageOfferingActivityRoster";

import { logout } from "./Redux/Actions/authAction";
import { clearMessage } from "./Redux/Actions/messageAction";

//import { history } from "./Helpers/history";
import { current } from "@reduxjs/toolkit";
let currentYear = new Date().getFullYear();

function App() {
  const { user: currentUser, msalInstance: currentMsalInstance } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  console.log("currentuser:", currentUser);
  console.log("companyId:", global.config.companyId);
  useEffect(() => {
    /*history.listen((location) => {
      dispatch(clearMessage()); // clear message when changing location
    });*/
    if (currentUser) {
      console.log("App******: " + JSON.stringify(currentUser));
    }
  }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // Custom logic to handle the refresh
      // Display a confirmation message or perform necessary actions
	  sessionStorage.removeItem("user");
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const logOut = () => {
    dispatch(logout());
    if (currentMsalInstance) {
      console.log("App******: Found msal login session");
      currentMsalInstance.logout();
    }
  };

  const myStyle = {
    width: "120px",
    height: "35px",
  };

  const rrStyle = {
    width: "200px",
    height: "65px",
  };

  const xStyle = {
    align: "center",
  };

  const yStyle = {
    align: "left",
    color: "black",
  };

  return (
    <BrowserRouter>
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <Link to={"/"} className="navbar-brand">
            <img src={rr_img} alt="" style={rrStyle} />
          </Link>

          {currentUser ? (
            <>
              <div className="navbar-nav ml-auto">
                <li className="nav-item" style={xStyle}>
                  { global.config.companyId === 'ford' || global.config.companyId === 'rldoraclelearn' ?
                        <img src={ford_img} alt="" style={rrStyle} />
                    : global.config.companyId === 'momentive' || global.config.companyId === 'rlddocebo' ?
                        <img src={mm_img} alt="" style={rrStyle} />
                      : global.config.companyId === 'costafarms' || global.config.companyId === 'rldschoox' ?
                          <img src={cf_img} alt="" style={rrStyle} />
                      : <img alt="" style={rrStyle} />
                  }
                </li>
              </div>
              <div className="navbar-nav ml-auto">
                <li className="nav-item" style={yStyle}>
                  <label>
                    <strong>
                      Hello,{" "}
                      {currentUser.firstname +
                        " " +
                        currentUser.lastname +
                        "    "}
                    </strong>
                  </label>
                </li>
                <li className="nav-item" style={yStyle}></li>
                <li className="nav-item">
                  <a href="/login" className="nav-link" onClick={logOut}>
                    <button type="button" className="btn btn-primary">
                      Log out
                    </button>
                  </a>
                </li>
              </div>
            </>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <Link to={"/login"} className="nav-link">
                  <button type="button" className="btn btn-primary">
                    Log in
                  </button>
                </Link>
              </li>
            </div>
          )}
        </nav>

        <div className="container mt-3">
          <Routes>
            <Route
              exact
              path="/"
              element={currentUser ? <Home /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/login"
              element={!currentUser ? <Login /> : "already logged in"}
            />
            <Route
              exact
              path="/profile"
              element={currentUser ? <Profile /> : <Navigate to="/login" />}
            />
            <Route
              exact
              path="/selectRoster"
              element={
                currentUser ? <SelectRoster /> : <Navigate to="/login" />
              }
            />
            <Route
              exact
              path="/manageRoster"
              element={
                currentUser ? <ManageRoster /> : <Navigate to="/login" />
              }
            />
            <Route
              exact
              path="/manageCourseRoster"
              element={
                currentUser ? <ManageCourseRoster /> : <Navigate to="/login" />
              }
            />
            <Route
              exact
              path="/manageOfferingActivityRoster"
              element={
                currentUser ? <ManageOfferingActivityRoster /> : <Navigate to="/login" />
              }
            />
          </Routes>
        </div>
      </div>
      <footer
        style={{
          width: "100%",
          verticalAlign: "bottom",
          fontSize: "14px",
          color: "black",
          position: "relative",
          textAlign: "center",
          paddingTop: "10px",
        }}
      >
        Copyright © {currentYear} TiER1 Performance
        <br />v{process.env.REACT_APP_VERSION}
      </footer>
    </BrowserRouter>
  );
}

export default App;
