import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  Card,
  Row,
  Col,
  InputGroup,
  FormControl,
  Table,
  Alert,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./uploadRosterModal.css";
import axios from "axios";
import * as XLSX from "xlsx/xlsx.mjs";
/* load 'fs' for readFile and writeFile support */
//import * as fs from "fs";

const UploadRosterModal = ({ course, currentEnrollments, setCurrentEnrollments, rosterData, setRosterData }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [importedDataString, setImportedDataString] = useState("");
  const [displayRosterData, setDisplayRosterData] = useState([]);

  useEffect(() => {
    console.log("course:", course);
  },[]);

  const handleRosterFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setIsFilePicked(true);
    //Set filesystem
    //XLSX.set_fs(fs);
  };

  async function handleImportRosterFile(e) {
    e.preventDefault();
    setIsDataLoaded(true);
    const file = selectedFile;
    const data = await file.arrayBuffer();
    /* data is an ArrayBuffer */
    //we use the native excel date numeric
    //to ensure that it's read properly by JSON
    const workbook = XLSX.read(data, {
      type: "binary",
      //cellDates: true,
      dateNF: "yyyy/mm/dd hh:mm:ss",
    });

    console.log(workbook);
    /* Find the data range and start reading rows */
    let first_sheet_name = workbook.SheetNames[0];
    let worksheet = workbook.Sheets[first_sheet_name];
    /*
    read the incoming course id to make sure 
    it matches the selected course id
    */
    const courseId = worksheet['C3'].v;
    let companyId = global.config.companyId;
    let learnerID = ''

    console.log("courseId",courseId);
    if(courseId !== course.course_id){
      setHasError(true);
    }else{
      setHasError(false);
      const sheetData = XLSX.utils.sheet_to_json(worksheet, {
        defval: "",
        dateNF: "14",
        blankrows: false,
        range: 7, //set starting row num, 1 past header
      });
      
      //now prep the actual data for storage to the LMS
      const tempRosterData = sheetData.map((u) => {
        console.log(u.Completed_On)
        
        learnerID = u.ID;
        console.log('BEFORE learnerID: ' + learnerID + ' companyId: ' + companyId)

        if (learnerID != null && learnerID != '') {
          if (companyId === 'costafarms') {
            learnerID = addLeadingZeros(learnerID, 6)
          }
        }
        console.log('AFTER learnerID (with Leading Zeroes): ' + learnerID + ' companyId: ' + companyId)


        return {
          id: learnerID,
          firstName: u.First_Name,
          lastName: u.Last_Name,
          completionDate: convertExcelSerialToDate (u.Completed_On).toLocaleDateString(),
          grade: "",
          score: "",
          //comments: "test",
        };
      });
      console.log(tempRosterData);
      //this will be used to store the records in the LMS
      setRosterData(tempRosterData);
  
      //setup the preview display
      const listItems = tempRosterData.map((user,index) => (
        <tr key={`user_${index}`}>
          <td>{user.id}</td>
          <td>{user.firstName}</td>
          <td>{user.lastName}</td>
          <td>{user.completionDate}</td>
          {/* <td>{user.comments}</td> */}
        </tr>
      ));
      //this will be used for preview display only
      setDisplayRosterData(listItems);
    }
  }

  const addLeadingZeros = (num, totalLength) => {
    return String(num).padStart(totalLength, '0');
  }

  const formatYmd = (date) => {
    return date.toISOString().slice(0, 10);
  };

  // function to convert excel date to normal js date
  function convertExcelSerialToDate (serial) {
    // Deal with time zone, otherwise you may see date offset +/- 1
   var step = new Date().getTimezoneOffset() <= 0 ? 25567 + 2 : 25567 + 1;
   var utc_days  = Math.floor(serial - step);
   var utc_value = utc_days * 86400;                                        
   var date_info = new Date(utc_value * 1000);

   var fractional_day = serial - Math.floor(serial) + 0.0000001;

   var total_seconds = Math.floor(86400 * fractional_day);

   var seconds = total_seconds % 60;

   total_seconds -= seconds;

   var hours = Math.floor(total_seconds / (60 * 60));
   var minutes = Math.floor(total_seconds / 60) % 60;

   return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
 }
 
  
  return (
    <div>
      <input
        type="file"
        name="offline_roster_file"
        onChange={handleRosterFileChange}
      />
      <Button variant="primary" onClick={handleImportRosterFile} 
            disabled={loading || !isFilePicked}>
        Preview Import
      </Button>
      {hasError && (
        <Alert variant="warning" className="mt-2">
        <h4>You are attempting to import a course roster that is not for the selected course. Please select a roster for this course.</h4>
      </Alert>
      )}
      {!hasError && isDataLoaded && (
        <>
          <Alert variant="info" className="mt-2">
            <h4>Preview the records that will be imported:</h4>
          </Alert>
          <div className="scrolling-container">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Completed On</th>
                {/* <th>Comments</th> */}
              </tr>
            </thead>
            <tbody>{displayRosterData}</tbody>
          </Table>
          </div>
        </>
      )}
    </div>
  );
};

export default UploadRosterModal;
